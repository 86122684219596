import React from 'react';
import ReactDOM from 'react-dom';

type Props = {
  className?: string;
  id?: string;
  children: React.ReactNode;
};

export const Portal:React.FC<Props> = ({
  // Props
  className, id, children
}) => {
  const element = React.useMemo(() => {
    const div:HTMLDivElement = document.createElement('div');
    if ( id ) div.id = id;
    div.className = className || 'relative z-40';
    return div;
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const target:HTMLElement = document.body;
    target.appendChild(element);
    return () => {
      target.removeChild(element);
      console.log(`Portal ${id || 'custom'} is destroyed`);
    }
    // eslint-disable-next-line
  }, [element]);

  return ReactDOM.createPortal(children, element);
};
