import { FC, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getWorkflows } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.async';
// Selectors
import { getPrompts } from 'app/store/AIChatPrompts/AIChatPrompts.async';
import { selectWorkflows } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.selectors';
// Components
import DataLoading from 'app/components/DataLoading';
// Mui
import {
  Box, Toolbar, Typography, Container, Paper,
  List
} from '@mui/material';
// 
import AutomatedWorkflowsFab from './AutomatedWorkflowsFab';
import WorkflowItem from './WorkflowItem';

const AutomatedWorkflowsPage:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const workflows = useAppSelector(selectWorkflows);

  useEffect(() => {
    dispatch(getPrompts({ owners: 'self,platform' }));
    dispatch(getWorkflows({}));
    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <Toolbar variant="dense">
        <Typography variant="h6">{t('pages.adminPages.automatedWorkflowsPage.title')}</Typography>
      </Toolbar>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Container sx={{ pt: 4, pb: 20 }} maxWidth="sm">
          <DataLoading
            data={workflows}
            text={t('pages.adminPages.automatedWorkflowsPage.noDataText')}
          >
            {(data) => (
              <Paper variant="outlined">
                <List>
                  {data.map((workflow) => (
                    <WorkflowItem
                      key={`workflow item ${workflow.id}`}
                      workflow={workflow}
                    />
                  ))}
                </List>
              </Paper>
            )}
          </DataLoading>
        </Container>
      </Box>
      <AutomatedWorkflowsFab />
    </Fragment>
  );
};

export default AutomatedWorkflowsPage;
