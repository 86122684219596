import { ReactNode } from 'react';
// Mui
import { Theme, Box, SxProps } from '@mui/material';

type Props = {
  sx?: SxProps;
  children: ReactNode;
}

const GroupContainer = ({ children }:Props) => {
  return (
    <Box sx={{
      maxWidth: 1200,
      width: '100%',
      // display: 'grid',
      // gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
      // justifyContent: 'center',
      display: 'flex',
      flexWrap: 'wrap', /* Allow child elements to wrap to the next line */
      justifyContent: 'center',
      position: 'relative',
      border: (theme:Theme) => `1px solid ${theme.palette.divider}`,
      borderRadius: 1,
      gap: 4,
      p: 4
    }}>{children}</Box>
  )
}

const Item = ({ sx, children }:Props) => {
  return (
    <Box sx={{
      maxWidth: '200px', /* Max width for child elements */
      flex: '1 1 200px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      bgcolor: (theme:Theme) => theme.palette.primary.light,
      borderRadius: 1,
      gap: 4,
      p: 4,
      ...sx
    }}>{children}</Box>
  );
}

GroupContainer.Item = Item;

export default GroupContainer