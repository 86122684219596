import { FC, useEffect } from 'react';
// Models
import { RootState } from 'app/store';
import IInsuranceCase from 'app/models/Case';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getPrompts } from 'app/store/AIChatPrompts/AIChatPrompts.async';
// Actions
import { AIChatActions } from 'app/store/AIChat/AIChat.slice';
// Selectors
import { selectPendingTermsAndConditionsByScope } from 'app/store/Users/Users.selectors';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectDocuments } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import { Loader } from 'app/components/Utilities';
// Hooks
import useCasePermissionResolved from 'app/hooks/useCasePermissionResolver';
// 
import AIChatSidebar from './AIChatSidebar';
import AIChatRoutes from './AIChatRoutes';
import AIChatTermsAndConditions from './AIChatTermsAndConditions';

const AIChatPage:FC = () => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;
  const documents = useAppSelector(selectDocuments);
  const pendingTermsAndConditions = useAppSelector((state:RootState) => selectPendingTermsAndConditionsByScope(state, {
    scope: 'aiChat'
  }));

  const { caseAIChatEnabled, isCaseProcessingReadyOrNotStarted } = useCasePermissionResolved();

  useEffect(() => {
    return () => {
      dispatch(AIChatActions.resetState());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( !caseAIChatEnabled || !isCaseProcessingReadyOrNotStarted ) return;

    const teamId:number | undefined = insuranceCase.team?.id;

    dispatch(getPrompts({ teamId, owners: 'self,platform' }));
    // eslint-disable-next-line
  }, [caseAIChatEnabled, isCaseProcessingReadyOrNotStarted]);

  const showAIChatTermsAndConditions = pendingTermsAndConditions && pendingTermsAndConditions.length

  if ( !documents ) return <Loader />;
  if ( showAIChatTermsAndConditions ) return <AIChatTermsAndConditions />;
  return (
    <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
      <AIChatSidebar />
      <AIChatRoutes />
    </Box>
  )
}

export default AIChatPage;
