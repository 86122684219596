import { createAsyncThunk } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Actions
import { DMSBatchesActions } from 'app/store/DMSBatches/DMSBatches.slice';
import { DMSUploadsActions } from "./DMSUploads.slice";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $post } from 'app/utilities/HttpClient';

const _url:string = `/dms/uploads`;

export const createFileUploadToken = asyncThunkHandler(
  `${Reducers.DMSUploads}/Create file upload token`,
  async (data:any) => {
    const response:Response = await $post(`${_url}/tokens`, data);
    return response.json();
  }
);

export const createPreSignedUrlForFile = asyncThunkHandler(
  `${Reducers.DMSUploads}/Create pre signed url for file`,
  async ({ index, data }:{ index:number, data:any }, { rejectWithValue, dispatch }) => {
    const response:Response = await $post(`${_url}/presignedUrls`, data);
    if ( !response.ok ){
      dispatch(DMSUploadsActions.addPreSignedUrl({ index, url: null }));
      return rejectWithValue((await response.json()) as any);
    }
    return response.json();
  }
);

export const uploadFileByPreSignedUrl = createAsyncThunk(
  `${Reducers.DMSUploads}/Upload file by pre signed url`,
  async ({ url, file, index }:{ url:string; file:File, index:number }, { rejectWithValue, dispatch, fulfillWithValue }) => {
    const response = await fetch(url, { method: 'PUT', body: file });
    if ( !response.ok ){
      let xmlMessage;

      try {
        xmlMessage = await parseXMLMessage(response);
      } catch(e){
        console.log(e);
      }

      dispatch(DMSBatchesActions.addFileError({
        index,
        errorType: 'size'
      }));
      // Return `true` to write local `s3Errors` value
      return rejectWithValue({
        statusCode: response.status,
        message: xmlMessage
      });
    }
    return fulfillWithValue(false);
  }
)

const parseXMLMessage = async (response:Response) => {
  const rawText = await response.text();

  try {
    // Try to parse the text as JSON
    const data = JSON.parse(rawText);
    return data;
  } catch (jsonError) {
    // If JSON parsing fails, try parsing as XML
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(rawText, 'application/xml');
    // Check if there are parsing errors in the XML
    if ( xmlDoc.getElementsByTagName('parsererror').length ){
      throw new Error('Failed to parse response as JSON or XML');
    }
    // Example: Extract a message from an XML element <Message>
    const messageElement = xmlDoc.getElementsByTagName('Message')[0];
    return messageElement?.textContent || 'Unrecognized error'; // Get the text inside <Message>
  }
}
