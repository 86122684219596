import { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import { IWorkflow } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.models';
// Store
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectLoading } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.selectors';
// Mui
import { Box, Typography } from '@mui/material'
// Components
import { Button, LoadingButton } from 'app/components/Mui';

type Props = {
  workflow?: IWorkflow;
  onClose: () => void;
}

const DialogTitle:FC<Props> = ({
  // Props
  workflow, onClose
}) => {
  const { t } = useTranslation('common');

  const label = workflow ? t('labels.update') : t('labels.create');
  // State
  const loading = useAppSelector(selectLoading);

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      borderBottom: '1px solid rgba(0,0,0,0.08)',
      px: 6,
      py: 4
    }}>
      <Typography variant="h6">{`${label} workflow`}</Typography>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2
      }}>
        <Button
          name="Cancel workflow form dialog"
          onClick={onClose}
          variant="outlined"
        >{t('labels.close')}</Button>
        <LoadingButton
          name={`${label} workflow form dialog`}
          type="submit"
          loading={loading}
          variant="contained"
        >{label}</LoadingButton>
      </Box>
    </Box>
  )
}

export default DialogTitle;
