import { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { apply_patch } from 'jsonpatch';
import i18n from 'i18n';
// Types
import UserRoles from 'app/types/UserRoles';
import NotificationStatuses from 'app/types/NotificationStatuses';
import SocketMessage from 'app/types/SocketMessages';
// Models
import { AppDispatch } from 'app/store';
import { IMyUser } from 'app/models/User';
import IEpisode from 'app/models/Episode';
// Async
import { getDuplicates, getInsuranceCases, getPendingCases } from 'app/store/Cases/Cases.async';
import { getDocumentPages } from 'app/store/DMSDocumentPages/DMSDocumentPages.async';
import { getDocumentEpisodes } from 'app/store/Episodes/Episodes.async';
import { getUnseenConversations } from 'app/store/Conversations/Conversations.async';
import { getMessages } from 'app/store/Messages/Messages.async';
// Action
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
import { AuthActions } from 'app/store/Auth/Auth.slice';
import { CasesActions } from 'app/store/Cases/Cases.slice';
import { DMSDocumentsActions } from 'app/store/DMSDocuments/DMSDocuments.slice';
import { EpisodesActions } from 'app/store/Episodes/Episodes.slice';
// Selectors
import { selectDialogName } from 'app/store/AppUIDialog/AppUIDialog.selectors';
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectInsuranceCase, selectCaseForJsonPatch } from 'app/store/Cases/Cases.selectors';
import { selectDocumentEntity } from 'app/store/DMSDocuments/DMSDocuments.selectors';
import { selectEpisodeEntity } from 'app/store/Episodes/Episodes.selectors';
// Services
import WebSocketService from 'app/services/WebSocketService';
import IdempotencyKeyService from 'app/services/IdempotencyKey.service';
// Factory
import PageFactory from 'app/factories/Page.factory';
import EpisodeFactory from 'app/factories/Episode.factory';

// ToDO
import { selectPageEntity } from 'app/store/page/page.selectors';
import PageActions from 'app/store/page/page.actions';
import IInsuranceCase from 'app/models/Case';
import { IPage } from '@root/models/Page';
import { hasOperationByPathType } from 'app/utilities/Utilities';
import { AIChatActions } from 'app/store/AIChat/AIChat.slice';
// End: ToDO

const SocketMiddleware:Middleware = ({ getState, dispatch }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  const { type, payload } = action;

  if ( type === AuthActions.setAuthenticated.type ){
    if ( !payload ){
      WebSocketService.close();
      return next(action);
    }

    WebSocketService.connect();
    WebSocketService.addMessageListener((message:SocketMessage) => {
      const { pathname, search } = window.location;

      const state = getState();
      const insuranceCase = selectInsuranceCase(state);
      const myUser:IMyUser | null = selectMyUser(state);

      const isMyUserAdminOrStaff = myUser?.role === UserRoles.Admin || myUser?.role === UserRoles.Staff;

      const { name, data, metadata } = message;

      console.log('Event name', name);
      console.log('Data', data);
      console.log('Metadata', metadata);

      const idempotencyKey = metadata?.idempotencyKey;

      if ( name === 'system.message' ){
        dispatch(AppUiNotificationsActions.addSystemMessage({
          type: 'message',
          message: data.message
        }));
      }

      if ( name === 'system.newVersion' && data.module === 'case-portal' ){
        dispatch(AppUiNotificationsActions.addSystemMessage({
          type: 'version',
          message: 'A new version of CaseChronology is available! Please reload a page to get latest version loaded.'
        }))
      }

      if ( name === 'deployment.started' && (data.module === 'cases-api' || data.module === 'ocr') ){
        dispatch(AppUiNotificationsActions.addSnackbar({
          message: i18n.t('notifications.socketMiddleware.casesApiServiceToBeDeployed'),
          options: { variant: NotificationStatuses.Info }
        }));
      }

      if ( name === 'deployment.completed' && (data.module === 'cases-api' || data.module === 'ocr') ){
        dispatch(AppUiNotificationsActions.addSnackbar({
          message: i18n.t('notifications.socketMiddleware.casesApiServiceDeployed'),
          options: { variant: NotificationStatuses.Info }
        }))
      }

      
      if (
        !idempotencyKey ||
        (
          !IdempotencyKeyService.hasKey(idempotencyKey) &&
          !IdempotencyKeyService.hasKeyInDuplicate(idempotencyKey)
        )
      ){
        if ( idempotencyKey && !IdempotencyKeyService.hasKeyInDuplicate(idempotencyKey) ){
          IdempotencyKeyService.addDuplicateKey(idempotencyKey);
        }

        if ( name === 'case.created' ){
          dispatch(AppUiNotificationsActions.addSystemMessage({
            type: 'message',
            message: `Case <i>${data.name}</i> is created`
          }));
  
          if ( pathname === '/admin/cases' ){
            dispatch(getPendingCases({}));
  
            const tab = search ? new URLSearchParams(search).get('tab') : null;
  
            if ( tab && tab === 'pending' ){
              dispatch(getInsuranceCases({
                limit: 10,
                offset: 0,
                status: 'pending',
                sort: 'createdOn desc'
              }));
            }
          }
        }

        if ( name === 'case.updated' ){
          const dialogName = selectDialogName(state);

          const insuranceCase = selectCaseForJsonPatch(state, { caseId: metadata.id }) as IInsuranceCase | null;

          if ( insuranceCase ){
            try {
              const patchedCase = apply_patch(insuranceCase, data) as IInsuranceCase;
              dispatch(CasesActions.setInsuranceCaseUpdate(patchedCase));
              if (
                insuranceCase?.processing?.status !== 'ready' &&
                patchedCase?.processing?.status === 'ready' &&
                insuranceCase?.id === patchedCase.id
              ){
                dispatch(getDuplicates(patchedCase.id));
              }
              // Dispatch message if `operation` has labels update
              if ( dialogName === 'CaseLabelsDialog' && hasOperationByPathType(data, '/labels') ){
                dispatch(AppUiNotificationsActions.addPushAlert({
                  category: 'case',
                  type: 'labels',
                  alert: {
                    caseId: patchedCase.id,
                    message: 'Labels have just been modified by another user'
                  }
                }));
              }
              // Dispatch message if `operation` has notes update
              if ( dialogName === 'CaseNotesDialog' && hasOperationByPathType(data, '/notes') ){
                dispatch(AppUiNotificationsActions.addPushAlert({
                  category: 'case',
                  type: 'notes',
                  alert: {
                    caseId: patchedCase.id,
                    message: 'Notes have just been modified by another user'
                  }
                }));
              }
            } catch(e){
              console.error(e)
            }
          }
        }

        if ( insuranceCase?.id === metadata.caseId ){
          if ( name === 'document.updated' ){
            const documentId = metadata.id;
            const documentEntity = selectDocumentEntity(state, { documentId });
            if ( documentEntity ){
              try {
                const patchedDocument = apply_patch(documentEntity, data);
                dispatch(DMSDocumentsActions.setDocumentUpdate(patchedDocument));
                if (
                  documentEntity?.processing?.status !== 'ready' &&
                  patchedDocument?.processing?.status === 'ready'
                ){
                  dispatch(getDocumentPages({ documentId }));
                  dispatch(getDocumentEpisodes({ documentId, fields: 'author' }));
                }
              } catch(e){
                console.error(e)
              }
            }

          }
          // Page
          if ( name === 'page.updated' ){
            const pageEntity = selectPageEntity(state, {
              documentId: metadata.id.documentId,
              pageNum: metadata.id.pageNum
            }) as IPage;
            if ( pageEntity ){
              try {
                const pageFactory = PageFactory.createPage(pageEntity);
                const patchedPage = apply_patch(pageFactory, data);
                dispatch(PageActions.updatePagesWithSocket([patchedPage]));
              } catch(e){
                console.error(e)
              }
            }
          }
          if ( name === 'pages.updated' ){
            const pages:any = [];
            let hasFailedPatch = false;
            for ( let i = 0; i < metadata.ids.length; i++ ){
              const id = metadata.ids[i];
              const pageEntity = selectPageEntity(state, {
                documentId: id.documentId,
                pageNum: id.pageNum
              });
              if ( !pageEntity ) continue;
              const pageData = data[i];
              try {
                const pageFactory = PageFactory.createPage(pageEntity);
                const patchedPage = apply_patch(pageFactory, pageData);
                pages.push(patchedPage);
              } catch(e){
                hasFailedPatch = true;
              }
              if ( hasFailedPatch ) break;
            }
            if ( !hasFailedPatch ) dispatch(PageActions.updatePagesWithSocket(pages));
          }
    
          // Episode
          if ( name === 'episode.created' ){
            dispatch(EpisodesActions.createEpisodeWithSocket(data as any))
          }
          if ( name === 'episode.updated' ){
            const episodeEntity = selectEpisodeEntity(state, { episodeId: metadata.id }) as IEpisode;
            if ( episodeEntity ){
              try {
                const episodeFactory = EpisodeFactory.create(episodeEntity);
                const patchedEpisode = apply_patch(episodeFactory, data);
                dispatch(EpisodesActions.updateEpisodesWithSocket([patchedEpisode]));
              } catch(e){
                console.error(e)
              }
            }
          }
          if ( name === 'episodes.updated' ){
            const episodes:any = [];
            let hasFailedPatch = false;
            for ( let i = 0; i < metadata.ids.length; i++ ){
              const id = metadata.ids[i];
              const episodeEntity = selectEpisodeEntity(state, { episodeId: id });
              if ( !episodeEntity ) continue;
              const episodeData = data[i];
              try {
                const episodeFactory = EpisodeFactory.create(episodeEntity);
                const patchedEpisode = apply_patch(episodeFactory, episodeData);
                episodes.push(patchedEpisode);
              } catch(e){
                hasFailedPatch = true;
              }
              if ( hasFailedPatch ) break;
            }
            if ( !hasFailedPatch ) dispatch(EpisodesActions.updateEpisodesWithSocket(episodes));
          }
          if ( name === 'episode.deleted' ){
            dispatch(EpisodesActions.deleteEpisodesWithSocket([data.id]));
          }
          if ( name === 'episodes.deleted' ){
            dispatch(EpisodesActions.deleteEpisodesWithSocket(data.ids));
          }
        }
  
        if ( name === 'conversation.newMessage' && isMyUserAdminOrStaff ){
          dispatch(getUnseenConversations({}))
  
          const regExp = new RegExp(/\/messages\/(\d+)/);
          const matches = pathname.match(regExp);
  
          if ( matches ){
            const conversationId = Number(matches[1]);
            dispatch(getMessages({ conversationId, offset: 0, limit: 1000 }))
          }
        }
      }

      if ( name === 'aichat.message.created' ){
        dispatch(AIChatActions.addMessageFromSocket({
          conversationId: metadata.conversationId,
          idempotencyKey,
          data
        }));
      }

      if ( idempotencyKey && IdempotencyKeyService.hasKey(idempotencyKey) ){
        IdempotencyKeyService.removeKey(idempotencyKey);
      }
    });
  }

  return next(action);
};

export default SocketMiddleware;
