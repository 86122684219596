import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';
import { IExecution } from './AutomatedWorkflowsExecutions.models';
// Utilities
import { sortByCreationDate } from 'app/utilities/SortBy';

export const selectExecutions = (state:RootState) => state[Reducers.AutomatedWorkflowsExecutions].executions;
export const selectLoading = (state:RootState) => state[Reducers.AutomatedWorkflowsExecutions].loading;

export const selectSortedExecutions = createSelector(
  [ selectExecutions ],
  ( executions:IExecution[] | null ) => {
    if ( !executions ) return null;
    return [...executions].sort(sortByCreationDate);
  }
);
