import { FC, Fragment } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Store
import { useAppDispatch } from 'app/hooks/useStore';
// Actions
import { AppUiDialogActions } from 'app/store/AppUIDialog/AppUIDialog.slice';
// Mui
import { Box } from '@mui/material';
// Layouts
import FilesSidebar from 'app/layouts/DocumentsSidebar';
// Components
import { Message } from 'app/components/Utilities';
import { Button } from 'app/components/Mui';
// 
import DocumentDetailsPage from 'app/pages/Staff/CaseDetailsPage/DocumentDetailsPage';
import DocumentPageDetailsPage from 'app/pages/Staff/CaseDetailsPage/DocumentPageDetailsPage';

const DocumentsRouting:FC = () => {
  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();

  const handleOpenUploadDocumentsFormDialog = () => {
    dispatch(AppUiDialogActions.showDialog({
      dialogName: 'CaseUploadDocumentsFormDialog',
      dialogProps: {}
    }));
  }

  return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      overflow: 'hidden'
    }}>
      <FilesSidebar />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <Switch>
          <Route exact path={path} component={() => (
            <Fragment>
              <Message text={t('pages.adminPages.caseDetailsPage.noDocumentSelected')} />
              <Box sx={{ textAlign: 'center' }}>
                <Button
                  name="Upload documents"
                  onClick={handleOpenUploadDocumentsFormDialog}
                  variant="contained"
                >{t('pages.staffPages.caseDetailsPage.uploadDocuments')}</Button>
              </Box>
            </Fragment>
          )}/>
          <Route exact path={`${path}/:documentId`} component={DocumentDetailsPage} />
          <Route path={`${path}/:documentId/:pageNum`} component={DocumentPageDetailsPage} />
        </Switch>
      </Box>
    </Box>
  );
}

export default DocumentsRouting;
