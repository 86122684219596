import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { ICurrentAccount } from 'app/models/Account';
import IInsuranceCase from 'app/models/Case';
// Models
import { RootState } from 'app/store';
import { IPageDuplicate } from '@root/models/Page';
// Selectors
import { selectCurrentAccount } from 'app/store/Accounts/Accounts.selectors';
import { selectDocumentsEntities } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// ToDO
import { selectPagesDatesMap, selectPagesInWorkspaceIds } from 'app/store/page/page.selectors';
// Service
import LocalStorageService from 'app/services/LocalStorage.service';
import { generatePageId } from 'app/store/page/page.service';
// End:ToDO

export const selectInsuranceCase = (state:RootState) => state[Reducers.Cases].insuranceCase;

export const selectCasesList = (state:RootState) => state[Reducers.Cases].insuranceCases.list;
export const selectCasesParams = (state:RootState) => state[Reducers.Cases].insuranceCases.params;
export const selectCasesHasMore = (state:RootState) => state[Reducers.Cases].insuranceCases.hasMore;
export const selectCasesGettingMore = (state:RootState) => state[Reducers.Cases].insuranceCases.gettingMore;

export const selectDuplicatesList = (state:RootState) => state[Reducers.Cases].duplicates.list;
export const selectDuplicatesEntities = (state:RootState) => state[Reducers.Cases].duplicates.entities;
export const selectDuplicatesFilter = (state:RootState) => state[Reducers.Cases].duplicates.filter;

export const selectLoading = (state:RootState) => state[Reducers.Cases].loading;

export const selectPendingCases = (state:RootState) => state[Reducers.Cases].pendingCases;
export const selectConversationCases = (state:RootState) => state[Reducers.Cases].conversationCases;

export const selectAccountCasesParams = createSelector(
  [
    selectCurrentAccount,
    selectCasesParams
  ],
  ( currentAccount:ICurrentAccount | null, params:any ) => {
    if ( !currentAccount ) return params;

    const casesSorting = LocalStorageService.getCasesSorting(currentAccount.id);

    return {
      ...params,
      'sort': casesSorting['sort'] || 'createdOn desc',
      'sort.priority.collectionIds': casesSorting['sort.priority.collectionIds'] || ''
    }
  }
);

export const selectCaseForJsonPatch = createSelector(
  [
    selectInsuranceCase,
    selectCasesList,
    (_, props:{ caseId:number }) => props
  ],
  (insuranceCase:IInsuranceCase | null, cases:IInsuranceCase[] | null, { caseId }) => {
    if ( insuranceCase && insuranceCase.id === caseId ){
      return insuranceCase;
    } else {
      if ( !cases ) return null;
      return cases.find((c:IInsuranceCase) => c.id === caseId) || null;
    }
  }
)

export const selectPendingCasesLength = createSelector(
  [ selectPendingCases ],
  ( pendingCases:IInsuranceCase[] | null ) => {
    if ( !pendingCases ) return 0;
    return pendingCases.length;
  }
);

export const selectConversationCasesEntities = createSelector(
  [ selectConversationCases ],
  ( conversationCases:IInsuranceCase[] | null ) => {
    if ( !conversationCases ) return {};
    return conversationCases.reduce((acc:any, item:IInsuranceCase) => {
      acc[item.id] = item.name;
      return acc;
    }, {});
  }
);

export const selectCaseMedicareRate = createSelector(
  [ selectInsuranceCase ],
  ( insuranceCase:IInsuranceCase | null ) => {
    if ( !insuranceCase ) return 0;
    return insuranceCase?.settings?.medicareOverchargeThreshold || 0;
  }
);

export const selectCaseAiChatEnabled = createSelector(
  [ selectInsuranceCase ],
  ( insuranceCase:IInsuranceCase | null ) => {
    if ( !insuranceCase ) return false;
    return insuranceCase.settings?.aiChatEnabled || false;
  }
);

export const selectCaseReportTemplateSelected = createSelector(
  [ selectInsuranceCase ],
  ( insuranceCase:IInsuranceCase | null ) => {
    // if ( !insuranceCase ) return false;
    return Boolean(insuranceCase?.reportTemplateId);
  }
);

export const selectCasesListAsOptions = createSelector(
  [ selectCasesList ],
  ( cases:IInsuranceCase[] | null ) => {
    if ( !cases ) return null;
    return cases.map((insuranceCase:IInsuranceCase) => ({
      id: insuranceCase.id,
      name: insuranceCase.name
    }));
  }
);










export const selectFilteredDuplicates = createSelector(
  [
    selectDuplicatesList,
    selectDuplicatesFilter,
    (state:any) => selectPagesDatesMap(state),
    (state:any) => selectPagesInWorkspaceIds(state)
  ],
  (duplicates, filter, pagesDatesMap:any, pagesInWorkspaceIds:Array<string>) => {
    if ( !duplicates ) return null;

    const clustersMap:{ [key:string]:Array<IPageDuplicate> } = {};

    Object.keys(duplicates).forEach((key, i) => {
      const cluster = duplicates[key];
      const earliestDateInCluster = cluster.reduce((acc:string, cur) => {
        let pageId = generatePageId(cur.documentId, cur.pageNum);
        let pageDate = pagesDatesMap[pageId];
        if ( pageDate ){
          acc = acc ? new Date(pageDate) < new Date(acc) ? pageDate : acc : pageDate
        }
        return acc;
      }, '');
      clustersMap[
        earliestDateInCluster
        ? `${key}:${new Date(earliestDateInCluster)}`
        : `${key}:${new Date(new Date().setSeconds(i))}`
      ] = cluster;
    });

    const sortedDuplicates:Record<string, IPageDuplicate[]> = Object.keys(clustersMap).sort((a:string, b:string) => {
      const [,aDateString] = a.split(':');
      const [,bDateString] = b.split(':');

      const aDateTime = new Date(aDateString).getTime();
      const bDateTime = new Date(bDateString).getTime();
      return aDateTime < bDateTime ? -1 : aDateTime > bDateTime ? 1 : 0;
    }).reduce((acc, cur) => {
      const [key] = cur.split(':');

      return { ...acc, [key]: clustersMap[cur] }
    }, {})

    const { pages, documentBy } = filter;

    const filteredDuplicates = pages === 'all'
      ? sortedDuplicates
      : Object.keys(sortedDuplicates).reduce((acc:Record<string, IPageDuplicate[]>, key) => {
          const cluster = sortedDuplicates[key];
          let nextCluster:IPageDuplicate[] = [];
          for ( let page of cluster ){
            const pageId = generatePageId(page.documentId, page.pageNum);
            if ( pagesInWorkspaceIds.includes(pageId) ) {
              if ( pages === 'hasWorkspacePages' ) {
                nextCluster = cluster;
                break;
              }
              nextCluster.push(page);
            }
          }
          if ( nextCluster.length >= 2 ) acc[key] = nextCluster;
          return acc;
        }, {})
    ;

    if ( documentBy === 'all' ) return filteredDuplicates;

    return Object.keys(filteredDuplicates).reduce((acc:Record<string, IPageDuplicate[]>, key) => {
      const cluster = filteredDuplicates[key];
      if (cluster.filter((page) => page.documentId === documentBy).length > 0) {
        acc[key] = cluster;
      }
      return acc;
    }, {});
  }
);

export const selectDuplicateUniqueDocumentIds = createSelector(
  [ selectDuplicatesList ],
  ( duplicates ) => {
    if ( !duplicates ) return null;
    const result:number[] = [];
    Object.values(duplicates).forEach(cluster => {
      for ( let page of cluster ){
        if ( !result.includes(page.documentId) ) result.push(page.documentId);
      }
    })
    return result;
  }
);

export const selectDuplicateDocumentAsOptions = createSelector(
  [
    selectDuplicateUniqueDocumentIds,
    selectDocumentsEntities
  ],
  ( documentIds:number[] | null, entities:any ) => {
    if ( !documentIds || !Object.keys(entities).length ) return null;
    if ( !documentIds ) return null;
    return documentIds.map((documentId:number) => {
      const documentEntity = entities[documentId];
      return {
        id: documentEntity.id,
        name: documentEntity.name
      };
    })
  }
);
