const generateShortId = (size: number = 12):string => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  
  // Generate base-36 timestamp and take the last 5 characters
  const timestampPart = Date.now().toString(36).slice(-5);

  // Generate the random part to make up the remaining characters
  let randomPart = '';
  const randomSize = size - timestampPart.length;

  for ( let i = 0; i < randomSize; i++ ){
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomPart += characters[randomIndex];
  }

  // Combine the timestamp part and random part
  return `${timestampPart}${randomPart}`;
}

export default generateShortId;
