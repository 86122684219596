import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';
import ILabel from 'app/models/Label';
import IInsuranceCase from 'app/models/Case';
import { IPrompt } from './AIChatPrompts.models';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
// Utilities
import { sortByName } from 'app/utilities/SortBy';

export const selectPrompts = (state:RootState) => state[Reducers.AIChatPrompts].prompts;
export const selectPrompt = (state:RootState) => state[Reducers.AIChatPrompts].prompt;
export const selectFilter = (state:RootState) => state[Reducers.AIChatPrompts].filter;
export const selectLoading = (state:RootState) => state[Reducers.AIChatPrompts].loading;

export const selectSortedPrompts = createSelector(
  [ selectPrompts ],
  ( prompts:IPrompt[] | null ) => {
    if ( !prompts ) return null;
    return [...prompts].sort((a, b) => sortByName(a, b, 'title'));
  }
);

export const selectCaseAIChatPrompts = createSelector(
  [
    // Adde selected `selectInsuranceCase`
    // to add check on showing prompt from same `caseTeamId` if it exists
    selectInsuranceCase,
    selectSortedPrompts
  ],
  ( insuranceCase:IInsuranceCase | null, prompts:IPrompt[] | null ) => {
    if ( !prompts ) return null;

    const caseTeamId:number | undefined = insuranceCase?.team?.id;

    return prompts.filter((aiChatPrompt:IPrompt) => {
      if ( aiChatPrompt.platform ) return true;
      return caseTeamId
        ? !aiChatPrompt.teamIds || aiChatPrompt.teamIds.includes(caseTeamId)
        : !aiChatPrompt.teamIds
      ;
    });
  }
);

export const selectPromptsAsOptions = createSelector(
  [ selectSortedPrompts ],
  ( prompts:IPrompt[] | null ) => {
    if ( !prompts ) return null;
    return prompts.map((aiChatPrompt:IPrompt) => ({
      id: aiChatPrompt.id,
      name: aiChatPrompt.title,
      props: {
        content: aiChatPrompt.content
      }
    }));
  }
);

// Select filtered prompts
export const selectFilteredPrompts = createSelector(
  [
    selectInsuranceCase,
    selectFilter,
    selectSortedPrompts,
    selectCaseAIChatPrompts
  ],
  (insuranceCase:IInsuranceCase | null, { search }, allPrompts:IPrompt[] | null, casePrompts:IPrompt[] | null) => {
    if ( !allPrompts || !casePrompts ) return null;

    const prompts = insuranceCase ? casePrompts : allPrompts;

    if ( !search ) return prompts;

    const searchToLower = search.toLowerCase();

    return prompts.filter((aiChatPrompt:IPrompt) => {
      let hasSearch = aiChatPrompt.title.toLowerCase().includes(searchToLower);
      if ( !hasSearch ){
        const filteredLabels = aiChatPrompt.labels && aiChatPrompt.labels.length
          ? aiChatPrompt.labels.filter((label:ILabel) => label.name.toLowerCase().includes(searchToLower))
          : []
        ;
        hasSearch = Boolean(filteredLabels.length);
      }
      return hasSearch;
    });
  }
);
