import { FC } from 'react';
// Types
import { StepTaskLabels } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.types';
// Mui
import { Box, Fade, Paper, Toolbar, Typography } from '@mui/material';
// 
import { useWorkflowContext } from './Workflow.context';
import { RunPromptControls, CreateDocumentControls, CreateReportControls, SendEmailControls } from './StepTaskControlls';

const StepDetails:FC = () => {
  const { selectedStep } = useWorkflowContext();

  if ( !selectedStep ) return null;
  return (
    <Fade in={true}>
      <Paper>
        <Toolbar variant="dense" sx={{ borderRadius: '4px 4px 0 0' }}>
          <Typography variant="subtitle2">{StepTaskLabels[selectedStep.task]}</Typography>
        </Toolbar>
        <Box sx={{ pt: 2, pb: 4, px: 4 }}>
          <RunPromptControls />
          <CreateDocumentControls />
          <CreateReportControls />
          <SendEmailControls />
        </Box>
      </Paper>
    </Fade>
  )
}

export default StepDetails