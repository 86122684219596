import { FC, Fragment, useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/material';
// Models
import IFile from 'app/models/File';
import IInsuranceCase from 'app/models/Case';
import { IFileError } from 'app/store/DMSBatches/DMSBatches.models';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createBatch, validateBatch } from 'app/store/DMSBatches/DMSBatches.async';
import { createFileUploadToken, createPreSignedUrlForFile, uploadFileByPreSignedUrl } from 'app/store/DMSUploads/DMSUploads.async';
// Actions
import { DMSBatchesActions } from 'app/store/DMSBatches/DMSBatches.slice';
import { DMSUploadsActions } from 'app/store/DMSUploads/DMSUploads.slice';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectPreSignedUrls } from 'app/store/DMSUploads/DMSUploads.selectors';
import { selectFilesErrors } from 'app/store/DMSBatches/DMSBatches.selectors';
// Components
import Dialog from 'app/components/Dialog';
import { Button, LoadingButton } from 'app/components/Mui';
// 
import CommentControl from './CommentControl';
import FilesUploadProgress from './FilesUploadProgress';
import Files from './Files';
import UploadHints from './UploadHints';

interface IFormData {
  comment: string;
  files: IFile[];
}

type Props = {
  open: boolean;
  onClose: () => void;
};

let token:any = null;

const CaseUploadDocumentsFormDialog:FC<Props> = ({
  // Props
  open, onClose
}) => {
  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;
  const preSignedUrls = useAppSelector(selectPreSignedUrls);
  const filesErrors = useAppSelector(selectFilesErrors);

  const methods = useForm<IFormData>({
    defaultValues: {
      comment: '',
      files: []
    }
  });

  const [ loading, setLoading ] = useState(false);
  const [ uploadedFilesLength, setUploadedFilesLength ] = useState(0);

  useEffect(() => {
    return () => {
      token = null;

      dispatch(DMSBatchesActions.setInitialField('filesErrors'));
      dispatch(DMSUploadsActions.setInitialField('preSignedUrls'));
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = methods.handleSubmit(async (data:IFormData) => {
    const { comment, files = [] } = data;

    if ( !files.length ) return;
    if ( filesErrors.some((error:IFileError | null) => error && error.ui) ) return;

    const s3Errors:(boolean | null)[] = [];
    const nextData:any = { caseId: insuranceCase.id, files: [] };

    if ( comment ) nextData['comment'] = comment;

    setLoading(true);
    setUploadedFilesLength(0);

    try {
      if ( !token ){
        const response = await dispatch(createFileUploadToken({ caseId: insuranceCase.id })).unwrap();
        token = response.token;
      }

      nextData['token'] = token;

      for ( let i = 0; i < files.length; i++ ){
        const { file, type, collectionId, comment } = files[i];
        const fileData:any = { name: file.name, type };

        if ( comment ) fileData['annotations'] = [{ name: 'comment', value: comment }];

        const preSignedData:any = {
          token,
          file: {
            name: file.name,
            contentType: file.type
          },
          fileType: type
        };
        if ( collectionId ){
          fileData['collectionId'] = Number(collectionId);
          preSignedData['collectionId'] = Number(collectionId);
        }

        if ( !preSignedUrls.length || !preSignedUrls[i] ){
          const { url } = await dispatch(createPreSignedUrlForFile({ data: preSignedData, index: i })).unwrap();
          const s3Error = await dispatch(uploadFileByPreSignedUrl({ url, file, index: i })).unwrap();
          if ( s3Error ){
            s3Errors[i] = true;
          }
          dispatch(DMSUploadsActions.addPreSignedUrl({ index: i, url }));
        } else {
          s3Errors[i] = null;
        }

        nextData.files = [...nextData.files, fileData];
      }

      if ( s3Errors.some((error:boolean | null) => error !== null) ) return;
      const filesErrors = await dispatch(validateBatch(nextData)).unwrap();
      if ( filesErrors.some((error:IFileError | null) => error !== null) ) return;
      await dispatch(createBatch(nextData)).unwrap();
      onClose();
    } catch(error){
      console.error(error);
    } finally {
      setLoading(false);
    }
  });

  const actions = (
    <Fragment>
      <Button
        name="Cancel batch dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name="Upload batch dialog"
        loading={loading}
        onClick={onSubmit}
        color="primary"
        variant="contained"
      >{t('labels.upload')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      title={t('dialogs.batchCreate.uploadCaseMaterials')}
      actions={actions}
    >
      <FormProvider {...methods}>
        <form noValidate>
          <CommentControl />
          {loading ? (
            <Alert
              sx={{
                mt: 2
              }}
              color="warning"
              severity="warning"
            >Upload in progress. Closing this dialog will interrupt the upload.</Alert>
          ) : null}
          <FilesUploadProgress loading={loading} uploadedFilesLength={uploadedFilesLength} />
          <Files />
          <UploadHints />
        </form>
      </FormProvider>
    </Dialog>
  )
}

export default CaseUploadDocumentsFormDialog;
