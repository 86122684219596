import i18n from "i18n";

const Messages = {
  Execute: i18n.t('notifications.automatedWorkflowsExecutions.execute')
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Execute = i18n.t('notifications.automatedWorkflowsExecutions.execute');
});