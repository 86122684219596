import { FC, Fragment } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
// Models
import { IExecution } from 'app/store/AutomatedWorkflowsExecutions/AutomatedWorkflowsExecutions.models';
// Mui
import {
  Theme,
  Paper, Box, Divider, Typography
} from '@mui/material';
// Compoennts
import { InfoBlock } from 'app/components/Utilities';
// Utilities
import { statusThemeColor } from 'app/utilities/Utilities';
import { getDurationTimeAsText } from 'app/utilities/DateTime';

type Props = {
  execution: IExecution;
  showWorkflowName?: boolean;
}

const WorkflowExecutionCard:FC<Props> = ({
  // Props
  execution, showWorkflowName = true
}) => {
  const { t } = useTranslation('common');

  const durationTimeAsText = getDurationTimeAsText(execution.startedOn, execution.completedOn);

  const executionName = showWorkflowName
    ? execution.workflow.name
    : execution.case.name
  ;

  return (
    <Paper
      sx={{ p: 4 }}
      variant="outlined"
    >
      <Typography sx={{ mb: 2 }} variant="h6">{executionName}</Typography>
      <Box sx={{ display: 'flex', gap: 4 }}>
        <InfoBlock
          label={t('labels.status')}
          value={
            <Typography
              sx={{
                display: 'block',
                color: (theme:Theme) => statusThemeColor(execution.status, theme),
                fontWeight: 700,
                textTransform: 'capitalize'
              }}
              variant="caption"
            >{execution.status}</Typography>
          }
          direction="row"
        />
        {durationTimeAsText ? (
          <InfoBlock
            label={t('labels.duration')}
            value={durationTimeAsText}
            direction="row"
          />
        ) : null}
      </Box>
      {execution.startedOn || execution.completedOn ? (
        <Fragment>
          <Divider sx={{ borderColor: 'rgba(33,33,33,0.08)', my: 4 }} />
          <Box sx={{ display: 'flex', gap: 4 }}>
            {execution.startedOn ? (
              <Box sx={{ display: 'flex', gap: 4 }}>
                <InfoBlock
                  label={t('labels.startedOn')}
                  value={dayjs(execution.startedOn).local().format('MMMM DD, YYYY, hh:mm a')}
                />
              </Box>
            ) : null}
            {execution.completedOn ? (
              <InfoBlock
                label={t('labels.completedOn')}
                value={dayjs(execution.completedOn).local().format('MMMM DD, YYYY, hh:mm a')}
              />
            ) : null}
          </Box>
        </Fragment>
      ) : null}
    </Paper>
  )
}

export default WorkflowExecutionCard