import { FC } from 'react';
// Mui
import { Theme, Box, Typography } from '@mui/material';

type Props = {
  label: string;
}

const StepBlockStatic:FC<Props> = ({ label }) => (
  <Box
    sx={{
      maxWidth: 200,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: (theme:Theme) => `1px solid ${theme.palette.divider}`,
      borderRadius: 1,
      p: 4,
      textTransform: 'uppercase'
    }}
  ><Typography variant="body1">{label}</Typography></Box>
);

export default StepBlockStatic;
