import { FC, ReactNode, createContext, useContext, useMemo, useCallback, useState } from 'react';
// Types
import { StepTasks } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.types';
// Models
import { IWorkflowStep, IFieldStep } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.models';
import useWorkflowUtils from './useWorkflowUtils';

type ContextProps = {
  selectedStep: IWorkflowStep | undefined;
  onSelectStep: (step:IWorkflowStep) => void;
  getStepFieldIndex: (stepId:string) => number;
  stepsGroup:IWorkflowStep[][];
  stepCreateReport:IWorkflowStep | undefined;
  stepSendEmail:IWorkflowStep | undefined;

  onAddSteps: () => void;
  onRemoveSteps: (lastStepId:string) => void;
  onAddSendEmailStep: () => void;
};

const Context = createContext<ContextProps | undefined>(undefined);

export const useWorkflowContext = ():ContextProps => {
  const context = useContext(Context);
  if ( !context ) throw new Error('`useWorkflowContext` must be used within a `WorkflowProvider`');
  return context;
}

type Props = {
  fields:IFieldStep[];
  append: (data:IWorkflowStep[]) => void;
  remove: (indexes:number[]) => void;
  update: (index:number, data:IWorkflowStep) => void;
  children: ReactNode;
}

const WorkflowProvider:FC<Props> = ({ fields, append, remove, update, children }) => {
  const { generateStepsGroup, generateStepSendEmail } = useWorkflowUtils();

  const [ selectedStep, setSelectedStep ] = useState<IWorkflowStep | undefined>(undefined);

  const onSelectStep = useCallback((step:IWorkflowStep) => {
    // Same input value shown ( from prev `selectedStep` ) on newly `selectedStep` with same `task`
    // We need to add some `re-render` options
    setSelectedStep(undefined);
    setTimeout(() => {
      setSelectedStep(step);
    }, 0);
  }, []);

  const getStepFieldIndex = useCallback((stepId:string) => {
    return fields.findIndex((field:IFieldStep) => field.id === stepId);
  }, [fields]);

  const { stepsGroup, stepCreateReport, stepSendEmail } = useMemo(():any => {
    let stepsGroup:IWorkflowStep[][] = [];
    let stepCreateReport:IWorkflowStep | undefined = undefined;
    let stepSendEmail:IWorkflowStep | undefined = undefined;

    let group:IWorkflowStep[] = [];

    // Loop through steps backwards
    for ( let i = fields.length - 1; i >= 0; i--) {
      const step = fields[i] as IFieldStep;

      if ( step.task === StepTasks.CreateReport ){
        stepCreateReport = step;
      } else if ( step.task === StepTasks.SendEmail ) {
        stepSendEmail = step;
      } else {
        group.push(step);
        if ( !step.dependsOn ){
          stepsGroup.push(group.reverse()); // Reverse to maintain original order
          group = []; // Reset the group for the next potential group
        }
      }
    }

    return { stepsGroup: stepsGroup.reverse(), stepCreateReport, stepSendEmail };
    // eslint-disable-next-line
  }, [fields]);

  const updateCreateReportStepDependencies = useCallback((action:'add' | 'remove', dependsOnId:string) => {
    const idx = fields.findIndex((field:IFieldStep) => field.task === StepTasks.CreateReport);
    const field = fields[idx];
    const dependsOn = field.dependsOn || [];

    update(idx, {
      ...field,
      dependsOn: action === 'add'
        ? [...dependsOn, dependsOnId]
        : dependsOn.filter((id:string) => id !== dependsOnId)
    });
    // eslint-disable-next-line
  }, [fields, update]);

  const onAddSteps = useCallback(() => {
    const newStepsGroup = generateStepsGroup();

    const newDependsOnId = newStepsGroup[newStepsGroup.length - 1].id;

    updateCreateReportStepDependencies('add', newDependsOnId);

    append(newStepsGroup);
    // eslint-disable-next-line
  }, [fields, append]);

  const onRemoveSteps = useCallback((stepId:string) => {
    const indexesToRemove:number[] = [];
    const stepIds:string[] = [stepId];
    let currentStepId = stepId;

    while ( currentStepId ){
      const fieldIndex = getStepFieldIndex(currentStepId);
      const field = fields[fieldIndex];
      if ( field ){
        indexesToRemove.push(fieldIndex);
        if ( field.dependsOn && field.task !== StepTasks.SendEmail ){
          currentStepId = field.dependsOn[0];
          stepIds.push(currentStepId);
        } else {
          break;
        }
      } else {
        break;
      }
    }

    if ( selectedStep && stepIds.includes(selectedStep.id) ) setSelectedStep(undefined);

    updateCreateReportStepDependencies('remove', stepId);

    remove(indexesToRemove);
    // eslint-disable-next-line
  }, [selectedStep, fields, remove]);

  const onAddSendEmailStep = useCallback(() => {
    const newSendEmailStep = generateStepSendEmail([stepCreateReport.id]);

    append([newSendEmailStep]);
    // eslint-disable-next-line
  }, [fields, append]);

  return (
    <Context.Provider value={{
      selectedStep,
      onSelectStep,
      getStepFieldIndex,
      stepsGroup,
      stepCreateReport,
      stepSendEmail,

      onAddSteps,
      onRemoveSteps,
      onAddSendEmailStep
    }}>{children}</Context.Provider>
  );
}

export default WorkflowProvider;
