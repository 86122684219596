import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';
// Mui
import { Divider, FormHelperText } from '@mui/material';
// Components
import { Input, DesktopDatePicker } from 'app/components/Mui';
// Utility
import { isRequired, isMaxLength, isDateOfBirthValid } from 'app/utilities/Validations';

interface IFormData {
  claimant: {
    name: string;
    dateOfBirth: Dayjs | null;
  };
  claimNumber: string;
};

const ClaimantStepContent:FC = () => {
  const { t } = useTranslation('common');

  const { control, watch, formState:{ errors } } = useFormContext<IFormData>();

  const watchedClaimantName = watch('claimant.name') as string;

  return (
    <Fragment>
      <Controller
        control={control} name="claimant.name"
        rules={{ required: isRequired, maxLength: isMaxLength(31) }}
        render={({ field }) => (
          <Input
            {...field}
            label={t('labels.name')}
            required
            error={Boolean(errors.claimant?.name)}
            helperText={errors.claimant?.name?.message || ''}
            InputProps={{
              endAdornment: (
                <FormHelperText
                  sx={{ p: 0, whiteSpace: 'nowrap' }}
                >{`${watchedClaimantName.length} / 31`}</FormHelperText>
              )
            }}
          />
        )}
      />
      <Controller
        control={control} name="claimant.dateOfBirth"
        rules={{
          validate: isDateOfBirthValid
        }}
        render={({ field }) => (
          <DesktopDatePicker
            {...field}
            label={t('labels.dateOfBirth')}
            inputProps={{
              error: Boolean(errors.claimant?.dateOfBirth),
              helperText: errors.claimant?.dateOfBirth?.message || ''
            }}
          />
        )}
      />
      <Divider sx={{ mt: 4, mb: 2 }} />
      <Controller
        control={control} name="claimNumber"
        rules={{ maxLength: isMaxLength(30) }}
        render={({ field }) => (
          <Input
            {...field}
            label={t('labels.claimNumber')} id="claimNumber"
            error={Boolean(errors.claimNumber)}
            helperText={errors.claimNumber?.message || ''}
            InputProps={{
              endAdornment: (
                <FormHelperText
                  sx={{ p: 0, whiteSpace: 'nowrap' }}
                >{`${watch('claimNumber').length} / 30`}</FormHelperText>
              )
            }}
          />
        )}
      />
    </Fragment>
  )
}

export default ClaimantStepContent;
