import { MiddlewareAPI, Middleware, isFulfilled, isRejected } from '@reduxjs/toolkit'
// Models
import { AppDispatch } from 'app/store';
// Async
import { logout } from 'app/store/Auth/Auth.async';
// Actions
import { RequestsActions } from 'app/store/Requests/Requests.slice';
import { selectAuthenticated } from 'app/store/Auth/Auth.selectors';

const RequestsMiddleware:Middleware = ({ dispatch, getState }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  const { type, payload } = action;

  if ( type.endsWith('/pending') ){
    const controller = new AbortController();
    action.meta.signal = controller.signal;
    dispatch(RequestsActions.addAbortController(controller));
    dispatch(RequestsActions.incrementActiveRequests());
  } else if ( isFulfilled(action) ){
    dispatch(RequestsActions.decrementActiveRequests());
  } else if ( isRejected(action) ){
    const state = getState();
    const authenticated = selectAuthenticated(state);

    if ( typeof payload !== 'undefined' ){
      const { statusCode } = payload;
      if ( authenticated && statusCode === 401 ){
        dispatch(RequestsActions.clearAbortControllers());
        dispatch(logout());
      }
    }

    dispatch(RequestsActions.decrementActiveRequests());
  }

  return next(action);
}

export default RequestsMiddleware;
