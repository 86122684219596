// Types
import Reducers from 'app/types/Reducers';
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from "./AutomatedWorkflows.messages";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put, $delete } from 'app/utilities/HttpClient';

const _url:string = '/workflows';

// Get workflows
export const getWorkflows = asyncThunkHandler(
  `${Reducers.AutomatedWorkflows}/Get workflows`,
  async () => {
    const response:Response = await $get(_url);
    return response.json();
  }
);
// Create workflow
export const createWorkflow = asyncThunkHandler(
  `${Reducers.AutomatedWorkflows}/Create workflow`,
  async (data:any, { dispatch }) => {
    const response:Response = await $post(_url, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Create
    }));
    return response.json();
  }
);
// Update workflow
export const updateWorkflow = asyncThunkHandler(
  `${Reducers.AutomatedWorkflows}/Update workflow`,
  async ({ id, data }:{ id:number, data:any }, { dispatch }) => {
    const response:Response = await $put(`${_url}/${id}`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));
    return response.json();
  }
);
// Delete workflow
export const deleteWorkflow = asyncThunkHandler(
  `${Reducers.AutomatedWorkflows}/Delete workflow`,
  async (id:number, { fulfillWithValue, dispatch }) => {
    await $delete(`${_url}/${id}`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Delete
    }));
    return fulfillWithValue(id);
  }
);
