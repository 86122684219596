// Types
import Reducers from 'app/types/Reducers';
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from "./AutomatedWorkflowsExecutions.messages";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post } from 'app/utilities/HttpClient';

const _url:string = '/workflows/executions';

// Get workflows
export const getExecutions = asyncThunkHandler(
  `${Reducers.AutomatedWorkflowsExecutions}/Get executions`,
  async (params:any) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);
// Create workflow
export const executeInstantWorkflow = asyncThunkHandler(
  `${Reducers.AutomatedWorkflowsExecutions}/Execute instant workflow`,
  async (data:any, { dispatch }) => {
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Execute
    }));
    const response:Response = await $post(_url, data);
    return response.json();
  }
);
