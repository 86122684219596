import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Async
import { chargeInvoice, batchChargeInvoices } from './BillingCharges.async';

interface IState {
  loading: boolean;
};

const initialState:IState = {
  loading: false
};

const slice = createSlice({
  name: Reducers.BillingCharges,
  initialState,
  reducers: {
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  },
  extraReducers(builder) {
    // Charge invoice
    builder.addCase(chargeInvoice.pending, (state) => {
      state.loading = true;
    });
    // Bulk charge invoice
    builder.addCase(batchChargeInvoices.pending, (state) => {
      state.loading = true;
    });

    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  },
});

export const BillingChargesActions = slice.actions;

export default slice.reducer;
