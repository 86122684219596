// Types
import Reducers from "app/types/Reducers";
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from "./Episodes.messages";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put, $patch, $delete } from 'app/utilities/HttpClient';

const _url:string = '/episodes';

// Get document episodes
export const getDocumentEpisodes = asyncThunkHandler(
  `${Reducers.Episodes}/Get document episodes`,
  async (params:any) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);

// Get episodes
export const getEpisodes = asyncThunkHandler(
  `${Reducers.Episodes}/Get episodes`,
  async (params:any, { signal }) => {
    const response:Response = await $get(_url, params, {
      signal
    });
    return response.json();
  }
);

// Get episode
export const getEpisode = asyncThunkHandler(
  `${Reducers.Episodes}/Get episode`,
  async (episodeId:number) => {
    const response:Response = await $get(`${_url}/${episodeId}`);
    return response.json();
  }
);

// Create episode
export const createEpisode = asyncThunkHandler(
  `${Reducers.Episodes}/Create episode`,
  async (data:any, { dispatch, fulfillWithValue }) => {
    const { author, ...otherData } = data;
    const response:Response = await $post(_url, otherData);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Create
    }));
    if ( !author ) return response.json();
    const episode = await response.json();
    return fulfillWithValue({ ...episode, author });
  }
);

// Update episode
export const updateEpisode = asyncThunkHandler(
  `${Reducers.Episodes}/Update episode`,
  async ({ episodeId, data }:{ episodeId:number, data:any }, { rejectWithValue, dispatch, fulfillWithValue }) => {
    const { author, ...otherData } = data;
    const response:Response = await $put(`${_url}/${episodeId}`, otherData);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));
    if ( !author ) return response.json();
    const episode = await response.json();
    return fulfillWithValue({ ...episode, author });
  }
);

// Patch episode
export const patchEpisode = asyncThunkHandler(
  `${Reducers.Episodes}/Patch episode`,
  async ({ episodeId, data }:{ episodeId:number, data:any }, { rejectWithValue, dispatch, fulfillWithValue }) => {
    const response:Response = await $patch(`${_url}/${episodeId}`, data);
    const body = await response.json();
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));
    return fulfillWithValue({ id: episodeId, ...data, ...body });
  }
);

// Delete episode
export const deleteEpisode = asyncThunkHandler(
  `${Reducers.Episodes}/Delete episode`,
  async (episodeId:number, { dispatch, fulfillWithValue }) => {
    await $delete(`${_url}/${episodeId}`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Delete
    }));
    return fulfillWithValue(episodeId)
  }
);

// Delete episodes
export const deleteEpisodes = asyncThunkHandler(
  `${Reducers.Episodes}/Delete episodes`,
  async (episodeIds:number[], { fulfillWithValue }) => {
    await $delete(_url, { ids: episodeIds });
    return fulfillWithValue(episodeIds)
  }
);
