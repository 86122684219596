import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';
import IEpisodeAuthor from 'app/models/EpisodeAuthor';
// Utilities
import { sortByName } from 'app/utilities/SortBy';

export const selectAuthor = (state:RootState) => state[Reducers.EpisodeAuthors].author;
export const selectAuthors = (state:RootState) => state[Reducers.EpisodeAuthors].authors;
export const selectAuthorAnalytics = (state:RootState) => state[Reducers.EpisodeAuthors].authorAnalytic;
export const selectFilter = (state:RootState) => state[Reducers.EpisodeAuthors].filter;
export const selectLoading = (state:RootState) => state[Reducers.EpisodeAuthors].loading;

export const selectFilteredAuthors = createSelector(
  [
    selectAuthors,
    selectFilter
  ],
  ( authors:IEpisodeAuthor[] | null, filter ) => {
    if ( !authors ) return null;

    const { search, verified, organization } = filter;

    const sortedAuthors = [...authors].sort((a, b) => sortByName(a, b, 'name'));

    if ( !search && verified === 'all' && organization === 'all' ) return sortedAuthors;

    const searchToLower = search.toLowerCase();
    const verifiedToBoolean = verified === 'true' ? true : false;
    const organizationToBoolean = organization === 'true' ? true : false;

    return sortedAuthors.filter((author:IEpisodeAuthor) => {
      const hasSearch = searchToLower
        ? author.name.toLowerCase().includes(searchToLower)
          || author.episodeTypes.find((episodeType) => episodeType.toLowerCase().includes(searchToLower))
        : true;
      const hasVerified = verified !== 'all' ? author.verified === verifiedToBoolean : true;
      const hasOrganization = organization !== 'all' ? author.organization === organizationToBoolean : true;
      return hasSearch && hasVerified && hasOrganization;
    })
  }
);

export const selectAuthorsPaging = createSelector(
  [
    selectFilteredAuthors,
    selectFilter
  ],
  ( authors:IEpisodeAuthor[] | null, filter ) => {
    if ( !authors ) return null;

    const { limit, offset } = filter;

    return authors.slice(offset, offset + limit);
  }
)

export const selectAuthorsAsOptions = createSelector(
  [ selectAuthors ],
  ( authors:IEpisodeAuthor[] | null ) => {
    if ( !authors ) return null;
    return authors
      .map((author:IEpisodeAuthor) => ({
        id: author.id,
        name: author.name,
        props: {
          verified: author.verified,
          episodeTypes: author.episodeTypes
        }
      }));
  }
);
