import { forwardRef } from 'react';
// Mui
import {
  Box,
  FormHelperText,
  FormControlLabel as MuiFormControlLabel,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps
} from '@mui/material';
// Utilities
import { generateAttrForTesting } from 'app/utilities/Utilities';

type Props = MuiSwitchProps & {
  label: string;
  value: boolean | undefined;
  name: string;
  helperText?: string;
};

const Switch = forwardRef<any, Props>(({
  // Props
  label, value, helperText = '', ...otherProps
}, ref) => {
  return (
    <Box sx={{ pt: 2 }}>
      <MuiFormControlLabel
        {...generateAttrForTesting('control', 'checkbox', otherProps.name)}
        sx={{ mr: 2 }}
        ref={ref}
        label={label}
        disabled={otherProps.disabled}
        control={
          <MuiSwitch {...otherProps} checked={value} />
        }
      />
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </Box>
  )
})

export default Switch;
