import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Types
import { StepTasks } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.types';
// Components
import { Input } from 'app/components/Mui';
// 
import { useWorkflowContext } from '../Workflow.context'

const CreateReportControls:FC = () => {
  const { t } = useTranslation('common');

  const { selectedStep, getStepFieldIndex } = useWorkflowContext();

  const { control } = useFormContext();

  if ( !selectedStep || selectedStep.task !== StepTasks.CreateReport ) return null;

  const fieldIndex = getStepFieldIndex(selectedStep.id) as number;

  return (
    <Fragment>
      <Controller
        control={control}
        name={`steps[${fieldIndex}].input.parameters.name`}
        render={({ field }) => (
          <Input {...field} label={t('labels.name')} size="small" />
        )}
      />
    </Fragment>
  )
}

export default CreateReportControls;