import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import { IState, IExecution } from "./AutomatedWorkflowsExecutions.models";
// Async
import { getExecutions, executeInstantWorkflow } from './AutomatedWorkflowsExecutions.async';

const initialState:IState = {
  executions: null,
  loading: false
};

const slice = createSlice({
  name: Reducers.AutomatedWorkflows,
  initialState,
  reducers: {
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  },
  extraReducers: (builder) => {
    // Get executions
    builder.addCase(getExecutions.pending, (state) => {
      state.executions = null;
    });
    builder.addCase(getExecutions.fulfilled, (state, action:PayloadAction<IExecution[]>) => {
      state.executions = action.payload;
    });
    // Execute instant workflow
    builder.addCase(executeInstantWorkflow.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(executeInstantWorkflow.fulfilled, (state, action:PayloadAction<IExecution>) => {
      if ( state.executions ){
        state.executions.push(action.payload);
      }
    });
    // Match
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  }
});

export const AutomatedWorkflowsExecutionsActions = slice.actions;

export default slice.reducer;