import { FC } from 'react';
// Mui
import { Box } from '@mui/material';
// 
import SidebarWorkflowDetails from './SidebarWorkflowDetails';
import SidebarStepDetails from './SidebarStepDetails';

const DialogSidebar:FC = () => {
  return (
    <Box sx={{
      flexShrink: 0,
      width: 400,
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      p: 4,
      overflowY: 'auto'
    }}>
      <SidebarWorkflowDetails />
      <SidebarStepDetails />
    </Box>
  )
}

export default DialogSidebar