import { FC, Fragment } from 'react';
// i18next
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// Types
import UserRoles from 'app/types/UserRoles';
// Models
import { IMyUser } from 'app/models/User';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
// Mui
import { Divider, ListItemButton, ListItemText } from '@mui/material';

type Props = {
  onClose: () => void;
}

const AccountManagerMenuItems:FC<Props> = ({ onClose }) => {
  const { t } = useTranslation('common');
  // State
  const myUser:IMyUser | null = useAppSelector(selectMyUser);

  const listItems = [
    { to: '/am/company-accounts', type: 'item', label: t('components.userMenu.companyAccounts') },
    { to: '/am/users', type: 'item', label: t('labels.users') },
    { to: '/am/billing-invoices', type: 'item', label: t('components.userMenu.billingInvoices') },
    { to: '/am/analytics', type: 'item', label: t('labels.usageReport') },
    { to: '/am/subscription-packages', type: 'item', label: t('components.userMenu.subscriptionPackages') },
    { to: '/am/billing-orders', type: 'item', label: t('labels.orders') },
    { to: '/am/billing-services', type: 'item', label: t('labels.services') },
    { to: '/am/terms-and-conditions', type: 'item', label: t('components.userMenu.termsAndConditions') },
    { to: '/am/ai-prompt-hub', type: 'item', label: t('components.userMenu.aiPromptHub') },
    { type: 'divider' }
  ];

  if ( !myUser || myUser.role !== UserRoles.AccountManager ) return null;
  return (
    <Fragment>
      {listItems.map((listItem:any, index:number) => {
        if ( listItem.type === 'divider' ) return (
          <Divider key={`user menu list divider ${index}`} component="li" />
        );
        return (
          <ListItemButton
            key={`user menu list item ${index}`}
            component={Link}
            to={listItem.to}
            onClick={onClose}
          ><ListItemText primary={listItem.label} /></ListItemButton>
        );
      })}
    </Fragment>
  );
}

export default AccountManagerMenuItems;
