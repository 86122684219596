import { FC, useMemo } from 'react';
import { FormProvider, useForm, useFieldArray } from 'react-hook-form';
// Types
import { StepTasks, WorkflowTriggers, WorkflowType } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.types';
// Models
import { IWorkflow, IWorkflowStep } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.models';
// Store
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { createWorkflow, updateWorkflow } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.async';
// Mui
import { Box, Theme } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
// 
import WorkflowProvider from './Workflow.context';
import DialogTitle from './DialogTitle';
import DialogContainer from './DialogContainer';
import DialogSidebar from './DialogSidebar';
import useWorkflowUtils from './useWorkflowUtils';

type Props = {
  open: boolean;
  onClose: () => void;
  workflow?: IWorkflow;
  workflowType?: WorkflowType;
}

interface IFormData {
  name: string;
  type: WorkflowType;
  trigger?: WorkflowTriggers;
  conditions: {
    'document:collectionId': [],
    'case:labels': []
  };
  enabled: boolean;
  steps: IWorkflowStep[];
};

const WorkflowFormDialog:FC<Props> = ({
  // Props
  open, onClose, workflow, workflowType
}) => {
  const automatedWorkflowType = workflowType === WorkflowType.Automated;

  // Dispatch
  const dispatch = useAppDispatch();

  const { getInitialWorkflowConditions, getInitialWorkflowSteps } = useWorkflowUtils();

  const setWorkflowConditions = useMemo(() => {
    if ( !workflow ) return (automatedWorkflowType ? getInitialWorkflowConditions() : {});
    const conditions = workflow.conditions || [];
    return conditions.reduce((acc, cur) => {
      const { type, values } = cur;
      acc[type] = values.map((v:any) => `${v}`);
      return acc;
    }, {});
    // eslint-disable-next-line
  }, [workflow, automatedWorkflowType]);

  const methods = useForm<IFormData>({
    defaultValues: {
      name: workflow?.name || '',
      type: workflow?.type || workflowType,
      trigger: workflow?.trigger || (automatedWorkflowType ? WorkflowTriggers.DocumentBatchReady : undefined),
      conditions: setWorkflowConditions,
      enabled: typeof workflow !== 'undefined' ? workflow.enabled : true,
      steps: typeof workflow !== 'undefined' ? convertCoverageFromFloatToPercentage(workflow.steps) : getInitialWorkflowSteps()
    }
  });
  const { control, handleSubmit } = methods;

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'steps',
    keyName: '_id'
  });

  const onSubmit = handleSubmit(async (data:IFormData) => {
    const { conditions, steps, ...otherData } = data;

    const updateSteps = removeStepsEmptyParameters(steps)

    const newWorkflowData:any = {
      ...otherData,
      steps: convertCoverageFromPercentageToFloat(updateSteps)
    };

    if ( Object.keys(conditions).length ){
      const conditionsData = [];
      for ( let key in conditions ){
        const values = (conditions as any)[key];
        if ( values || (Array.isArray(values) && values.length) ){
          conditionsData.push({ type: key, values });
        }
      }
      if ( conditionsData.length ) newWorkflowData['conditions'] = conditionsData;
    }

    try {
      if ( workflow ){
        await dispatch(updateWorkflow({ id: workflow.id, data: newWorkflowData })).unwrap();
      } else {
        await dispatch(createWorkflow(newWorkflowData));
      }
  
      onClose();
    } catch(error){
      console.error(error);
    }
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      PaperProps={{ sx: { border: 0 } }}
      ContentProps={{ sx: { border: 0, p: 0 } }}
    >
      <FormProvider {...methods}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
          component="form"
          onSubmit={onSubmit}
          noValidate
        >
          <WorkflowProvider fields={fields} append={append} remove={remove} update={update}>
            <DialogTitle onClose={onClose} workflow={workflow} />
            <Box sx={{
              flexGrow: 1,
              bgcolor: (theme:Theme) => theme.palette.grey[50],
              display: 'flex',
              overflowY: 'auto'
            }}>
              <DialogContainer />
              <DialogSidebar />
            </Box>
          </WorkflowProvider>
        </Box>
      </FormProvider>
    </Dialog>
  )
}

export default WorkflowFormDialog

const removeStepsEmptyParameters = (steps:IWorkflowStep[]) => {
  for ( let step of steps ){
    // Check if input and input.parameters exist
    if ( !step.input || !step.input.parameters ) continue;

    for (const key in step.input.parameters) {
      const value = step.input.parameters[key];
      // Remove key if value is an empty string, null, undefined, or an empty array
      if ( !value || (Array.isArray(value) && !value.length) ){
        delete step.input.parameters[key];
      }
    }
  }
  return steps;
}

const convertCoverageFromFloatToPercentage = (steps:IWorkflowStep[]) => {
  return steps.map((step) => {
    if ( step.task === StepTasks.RunPrompt && step.input?.parameters?.hasOwnProperty('coverage') ){
      // Create a shallow copy of the step object to avoid mutating the original
      const newStep = { ...step };

      newStep.input = { ...step.input };
      newStep.input.parameters = { ...step.input.parameters };

      const { parameters } = newStep.input!;
      if ( parameters && typeof parameters.coverage === 'number' ){
        parameters.coverage = Math.round(parameters.coverage * 100); // Convert decimal to percentage
      }

      return newStep;
    }
    return step;
  });
}

const convertCoverageFromPercentageToFloat = (steps:IWorkflowStep[]) => {
  return steps.map((step) => {
    if (step.task === StepTasks.RunPrompt && step.input?.parameters?.hasOwnProperty('coverage')) {
      const { parameters } = step.input!; // non-null assertion operator
      if ( parameters && typeof parameters.coverage === 'string' ){
        // Parse the string to a number
        const coverageValue = parseFloat(parameters.coverage);
        if ( !isNaN(coverageValue) ){
          parameters.coverage = coverageValue / 100; // Convert percentage (number) to decimal
        }
      } else if (parameters && typeof parameters.coverage === 'number') {
        // Already a number, so just divide by 100
        parameters.coverage = parameters.coverage / 100;
      }
    }
    return step;
  });
}