import { FC, Fragment, useEffect } from 'react';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getInvoices } from 'app/store/BillingInvoices/BillingInvoices.async';
// Actions
import { BillingInvoicesActions } from 'app/store/BillingInvoices/BillingInvoices.slice';
// Selectors
import { selectParams } from 'app/store/BillingInvoices/BillingInvoices.selectors';
// Mui
import { Box, Container } from '@mui/material';
// 
import BillingInvoicesToolbar from './BillingInvoicesToolbar';
import BillingInvoicesFilter from './BillingInvoicesFilter';
import BillingInvoicesBatchPayment from './BillingInvoicesBatchPayment';
import InvoicesList from './InvoicesList';

const BillingInvoicesPage:FC = () => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const params = useAppSelector(selectParams);

  useEffect(() => {
    return () => {
      dispatch(BillingInvoicesActions.resetState());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { dateRangeStart, dateRangeEnd, accountId, ...otherParams } = params;

    dispatch(getInvoices({
      ...otherParams,
      'insuranceCase.clientAccountId': accountId
    }));
    // eslint-disable-next-line
  }, [params]);

  return (
    <Fragment>
      <BillingInvoicesToolbar />
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Container sx={{ my: 4 }} maxWidth="md">
          <BillingInvoicesFilter />
          <BillingInvoicesBatchPayment />
          <InvoicesList />
        </Container>
      </Box>
    </Fragment>
  )
}

export default BillingInvoicesPage;
