import { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
// Types
import Reducers from 'app/types/Reducers';
import MixpanelTracks from "app/types/MixpanelTracks";
// Models
import { AppDispatch } from 'app/store';
import IInsuranceCase from 'app/models/Case';
import { IAnnotation, IConversation } from 'app/models/ChatAI';
// Async
import { createEmbeddings, createConversation, updateConversation, refineAnswer } from 'app/store/AIChat/AIChat.async';
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
import { AIChatActions } from 'app/store/AIChat/AIChat.slice';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
// Services
import Mixpanel from "app/services/Mixpanel.service";
//
import AIChatMessages from 'app/store/AIChat/AIChat.messages';
import { selectConversation } from 'app/store/AIChat/AIChat.selectors';

let humanMessageText:string | undefined = undefined;

const AIChatMiddleware:Middleware = ({ getState, dispatch }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  const { type, payload } = action;

  if ( (type as string).startsWith(Reducers.AIChat) ){
    const state = getState()
    const insuranceCase = selectInsuranceCase(state) as IInsuranceCase;

    if ( type === createEmbeddings.fulfilled.type ){
      Mixpanel.track(MixpanelTracks.AIChatSettingsUpdate, { caseId: insuranceCase.id });
    }

    if ( type === createConversation.pending.type ){
      const arg = action.meta.arg;

      humanMessageText = arg.promptQuestion || arg.question;
    } else if ( type === createConversation.fulfilled.type ){
      const conversation = payload as IConversation;

      if ( humanMessageText ){
        dispatch(AIChatActions.setConversation({ conversation, humanMessageText }));
        dispatch(AIChatActions.setDisableGetConversation(true));
      }

      const source:string = conversation.annotations?.find((annotation:IAnnotation) => annotation.name === 'source')?.value || 'manual';

      Mixpanel.track(MixpanelTracks.AIChatConversationCreate, { 
        caseId: insuranceCase.id,
        conversationId: conversation.id,
        source
      });

      const { pathname } = window.location;

      const redirect = () => {
        dispatch(push(`/admin/cases/${insuranceCase.id}/ai-chat/${conversation.id}`));
      }
  
      if ( pathname.includes('ai-chat') ){
        redirect();
      } else {
        dispatch(AppUiNotificationsActions.addSnackbar({
          message: AIChatMessages.Create,
          onOpen: redirect
        }));
      }

      humanMessageText = undefined;
    }

    if ( type === updateConversation.fulfilled.type ){
      const conversation = payload as IConversation;
      Mixpanel.track(MixpanelTracks.AIChatConversationUpdate, {
        caseId: insuranceCase.id,
        conversationId: conversation.id
      });
    }
  
    if ( type === refineAnswer.pending.type ){
      const conversation = selectConversation(state);
      if ( conversation ){
        const { messageId } = action.meta.arg;
        Mixpanel.track(MixpanelTracks.AIChatConversationResponseRefinement, {
          caseId: insuranceCase.id,
          conversationId: conversation.id,
          messageId
        });
      }
    }
  }

  return next(action);
}

export default AIChatMiddleware;
