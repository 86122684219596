enum Reducers {
  // ToDO: Legacy
  Ui = 'ui',
  Pages = 'pages', // We already have `DMSDocumentPages` state for this
  // End ToDO
  AppUi = 'app/ui',
  AppUiNotifications = 'app/ui/notifications',
  AppUIDialog = 'app/ui/dialog',
  AppUIStatus = 'app/ui/status',
  Accounts = 'accounts',
  Auth = 'auth',
  CurrentUser = 'currentUser',
  AccountsManagement = 'accounts/management',
  UsersManagement = 'users/management',
  BillingInvoices = 'billing/invoices',
  BillingPackages = 'billing/packages',
  BillingServices = 'billing/services',
  BillingOrders = 'billing/orders',
  BillingCharges = 'billing/charges',
  Analytics = 'analytics',
  Presenter = 'presenter',
  Content = 'content',
  Episodes = 'episodes',
  EpisodeFacts = 'episode/facts',
  Medical = 'medical',
  Cases = 'cases',
  CaseAlerts = 'case/alerts',
  CaseReports = 'case/reports',
  CaseAssignments = 'case/assignments',
  CaseShares = 'case/shares',
  Conversations = 'conversations',
  Messages = 'messages',
  Calendar = 'calendar',
  Clients = 'clients',
  Teams = 'teams',
  Users = 'users',
  ExportTemplates = 'exportTemplates',
  Audit = 'audit',
  LabellingToolJobs = 'labellingTool/jobs',
  LabellingToolAnnotations = 'labellingTool/annotations',
  Templates = 'templates',
  EpisodeAuthors = 'episode/authors',
  ReportPresets = 'report/presets',
  Settings = 'settings',
  Integrations = 'integrations',
  ReportSections = 'report/sections',
  ReportSectionTemplates = 'report/section/templates',
  AIChat = 'aiChat',
  AIChatPrompts = 'aiChat/prompts',
  DMS = 'documentManagementService',
  DMSBatches = 'documentManagementService/batches',
  DMSDocuments = 'documentManagementService/documents',
  DMSDocumentPages = 'documentManagementService/document/pages',
  DMSCollections = 'documentManagementService/collections',
  DMSDocumentsExports = 'documentManagementService/documents/exports',
  DMSUploads = 'documentManagementService/uploads',
  Requests = 'requests',
  Terms = 'terms',
  Invoices = 'invoices',
  AutomatedWorkflows = 'automatedWorkflows',
  AutomatedWorkflowsExecutions = 'automatedWorkflows/executions',
}

export default Reducers;
