import { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
// Types
import UserRoles from 'app/types/UserRoles';
import Reducers from 'app/types/Reducers';
import MixpanelTracks from "app/types/MixpanelTracks";
// Models
import { AppDispatch } from 'app/store';
import { IPrompt } from 'app/store/AIChatPrompts/AIChatPrompts.models';
// Async
import { createPrompt, updatePrompt } from 'app/store/AIChatPrompts/AIChatPrompts.async';
// Selectors
import { selectCurrentAccount } from 'app/store/Accounts/Accounts.selectors';
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectInsuranceCase } from "app/store/Cases/Cases.selectors";
import { selectConversation } from 'app/store/AIChat/AIChat.selectors';
// Services
import Mixpanel from "app/services/Mixpanel.service";

const PromptsMiddleware:Middleware = ({ getState }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  const { type } = action;

  if ( (type as string).startsWith(Reducers.AIChatPrompts) ){
    const state = getState()
    const myUser = selectMyUser(state);

    // Skipped Mixpanel actions for `Acount manager`
    if ( myUser?.role === UserRoles.AccountManager ) return next(action);

    const currentAccount = selectCurrentAccount(state);
    const insuranceCase = selectInsuranceCase(state);
    const conversation = selectConversation(state);

    if ( type === createPrompt.fulfilled.type ){
      const aiChatPrompt = action.payload as IPrompt;
      const mixpanelData:any = { accountId: currentAccount.id, userId: myUser?.id, promptId: aiChatPrompt.id }

      let mixpanelAction = MixpanelTracks.AIChatPromptAdded;

      if ( insuranceCase && conversation ){
        mixpanelData['caseId'] = insuranceCase.id;
        mixpanelData['converationId'] = conversation.id;
        mixpanelAction = MixpanelTracks.AIChatConversationPromptSaved;
      }
  
      Mixpanel.track(mixpanelAction, mixpanelData);
    } else if ( type === updatePrompt.fulfilled.type ){
      const aiChatPrompt = action.payload as IPrompt;
  
      Mixpanel.track(MixpanelTracks.AIChatPromptUpdated, {
        accountId: currentAccount.id,
        userId: myUser?.id,
        promptId: aiChatPrompt.id
      });
    }
  }

  return next(action);
}

export default PromptsMiddleware;