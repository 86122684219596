import { useEffect } from 'react';
import { Switch, Route, Redirect, useRouteMatch, useParams } from 'react-router-dom';
//  Redux
import { useAppSelector } from 'app/hooks/useStore';
import { selectInvoicingEnabled, selectAIChatEnabled } from 'app/store/Accounts/Accounts.selectors';
// Pages
// Admin
import CalendarPage from 'app/pages/Admin/CalendarPage';
import OrdersPage from 'app/pages/Admin/CaseDetailsPage/OrdersPage';
import CaseSharesPage from 'app/pages/Admin/CaseSharesPage';
import DocumentsExportsPage from 'app/pages/Admin/CaseDetailsPage/DocumentsExportsPage';
import WorkflowsPage from 'app/pages/Admin/CaseDetailsPage/WorkflowsPage';
// Staff
import ReportsPage from 'app/pages/Staff/CaseDetailsPage/ReportsPage';
import AuditRecordsPage from 'app/pages/Staff/CaseDetailsPage/AuditRecordsPage';
import ConversationsPage from 'app/pages/Staff/CaseDetailsPage/ConversationsPage';
import TemplatesPage from 'app/pages/Staff/CaseDetailsPage/TemplatesPage';
import ReportSectionsPage from 'app/pages/Staff/CaseDetailsPage/ReportSectionsPage';
import AIChatPage from 'app/pages/Staff/CaseDetailsPage/AIChatPage';
import InvoicesPage from 'app/pages/Staff/CaseDetailsPage/InvoicesPage';
import ReportSectionPreviewPage from 'app/pages/Staff/CaseDetailsPage/ReportSectionPreviewPage';
// Routes
import DocumentsRouting from './DocumentsRouting';

// ToDO
import WorkspacePage from '@root/pages/AdminUser/WorkspacePage';
import DuplicatesPage from '@root/pages/AdminUser/DuplicatesPage';
import SearchPage from 'pages/Admin/CaseDetailsPage/SearchPage';
// End: ToDO

import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';

const CaseDetailsRouting = () => {
  const { path } = useRouteMatch();

  const { caseId } = useParams<{ caseId:string }>();

  const invoicingEnabled = useAppSelector(selectInvoicingEnabled);
  const aiChatEnabled = useAppSelector(selectAIChatEnabled);

  useEffect(() => {
    Mixpanel.track(MixpanelTracks.CaseView, { id: Number(caseId) });
    // eslint-disable-next-line
  }, []);

  return (
    <Switch>
      <Route path={`${path}/documents`} component={DocumentsRouting} />
      <Route path={`${path}/workspace`} component={WorkspacePage} />
      {invoicingEnabled ? <Route path={`${path}/invoices`} component={InvoicesPage} /> : null}
      <Route path={`${path}/audit-log`} component={AuditRecordsPage} />
      <Route path={`${path}/duplicates`} component={DuplicatesPage} />
      <Route path={`${path}/search`} component={SearchPage} />
      <Route path={`${path}/calendar`} component={CalendarPage} />
      <Route path={`${path}/reports`} component={ReportsPage} />
      <Route exact path={`${path}/report-sections`} component={ReportSectionsPage} />
      <Route path={`${path}/report-sections/:reportSectionId`} component={ReportSectionPreviewPage} />
      <Route path={`${path}/messages`} component={ConversationsPage} />
      <Route path={`${path}/documents-exports`} component={DocumentsExportsPage} />
      <Route path={`${path}/case-shares`} component={CaseSharesPage} />
      <Route path={`${path}/orders`} component={OrdersPage} />
      <Route path={`${path}/templates`} component={TemplatesPage} />
      <Route path={`${path}/workflows`} component={WorkflowsPage} />
      {aiChatEnabled ? <Route path={`${path}/ai-chat`} component={AIChatPage} /> : null}
      <Redirect from={path} to={`${path}/documents`} />
    </Switch>
  )
}

export default CaseDetailsRouting;
