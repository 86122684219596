import { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { captureMessage } from '@sentry/react';
// Models
import { AppDispatch } from 'app/store';
import IInsuranceCase from 'app/models/Case';
// Async
import { uploadFileByPreSignedUrl } from 'app/store/DMSUploads/DMSUploads.async';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { toSize } from 'app/utilities/Utilities';

const DMSUploadsMiddleware:Middleware = ({ dispatch, getState }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  const { type, payload } = action;

  if ( type === uploadFileByPreSignedUrl.rejected.type ){
    const { meta } = action;

    const file = meta.arg.file as File;

    const state = getState();
    const insuranceCase = selectInsuranceCase(state) as IInsuranceCase;

    captureMessage('DocumentsUploadFailed', {
      level: 'error',
      extra: {
        caseId: insuranceCase.id,
        portal: 'cases',
        fileSize: toSize(file.size),
        message: payload?.message || '',
        statusCode: payload?.statusCode || null
      }
    });
  }

  return next(action);
}

export default DMSUploadsMiddleware;
