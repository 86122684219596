import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from 'app/types/Reducers';
import CaseStatuses from 'app/types/CaseStatuses';
// Models
import IInsuranceCase from 'app/models/Case';
import ILabel from "app/models/Label";
import { IPageDuplicate } from "@root/models/Page";
// Async
import {
  getPendingCases, getConversationCases,
  getInsuranceCases, getInsuranceCase, createInsuranceCase, updateInsuranceCase, patchInsuranceCase, deleteInsuranceCase, statusesInsuranceCase,
  archiveInsuranceCase, unarchiveInsuranceCase, acceptInsuranceCase, rejectInsuranceCase, transmitInsuranceCase, createCaseDuplicate,
  getDuplicates, updateDuplicates, downloadCalendarFile
} from './Cases.async';
// Services
import PageService from "app/services/PageService";
import { uuidv4 } from "app/utilities/Utilities";

interface IState {
  insuranceCase: IInsuranceCase | null;
  insuranceCases: {
    list: IInsuranceCase[] | null;
    params: {
      limit: number;
      offset: number;
      status: CaseStatuses;
      search: string;
      'invoice.status': string;
      sort: string;
      'sort.priority.collectionIds': string;
    };
    hasMore: boolean;
    gettingMore: boolean;
  };
  duplicates: {
    list: Record<string, IPageDuplicate[]> | null;
    entities: Record<string, string[]>;
    filter: {
      documentBy: string | number;
      pages: 'all' | 'hasWorkspacePages' | 'hasWorkspaceDuplicates';
    }
  };

  loading: boolean;

  pendingCases: IInsuranceCase[] | null;
  conversationCases: IInsuranceCase[] | null;
}

const initialState:IState = {
  insuranceCase: null,
  insuranceCases: {
    list: null,
    params: {
      limit: 10,
      offset: 0,
      status: CaseStatuses.Open,
      search: '',
      'invoice.status': 'all',
      sort: 'createdOn desc',
      'sort.priority.collectionIds': ''
    },
    hasMore: false,
    gettingMore: false,
  },

  duplicates: {
    list: null,
    entities: {},
    filter: {
      documentBy: 'all',
      pages: 'all'
    }
  },

  loading: false,

  pendingCases: null,
  conversationCases: null
};

const slice = createSlice({
  name: Reducers.Cases,
  initialState,
  reducers: {
    setInsuranceCase: (state, action:PayloadAction<IInsuranceCase>) => {
      state.insuranceCase = action.payload;
    },
    setInsuranceCaseProcessingStatus: (state, action:PayloadAction<IInsuranceCase['processing']['status']>) => {
      if ( state.insuranceCase ){
        if ( state.insuranceCase['processing'] ){
          state.insuranceCase['processing']['status'] = action.payload;
        } else {
          const processing = (state.insuranceCase.processing || {}) as IInsuranceCase['processing'];
          state.insuranceCase = {
            ...state.insuranceCase,
            processing: {
              ...processing,
              status: action.payload
            }
          };
        }
      }
    },
    setInsuranceCaseUpdate: (state, action:PayloadAction<{ id:number, name:string, notes:string, labels:ILabel[] }>) => {
      const { id, ...otherCaseData } = action.payload;
      if ( state.insuranceCases.list ){
        state.insuranceCases.list = state.insuranceCases.list.map((insuranceCase:IInsuranceCase) => {
          if ( insuranceCase.id === id ) return { ...insuranceCase, ...otherCaseData };
          return insuranceCase;
        })
      }
      if ( state.insuranceCase && state.insuranceCase.id === id ){
        state.insuranceCase = { ...state.insuranceCase, ...otherCaseData };
      }
    },
    setAcceptInsuranceCase: (state, action:PayloadAction<IInsuranceCase>) => {
      if ( state.insuranceCases.list && state.insuranceCases.params.status === CaseStatuses.Open ){
        state.insuranceCases.list = [...state.insuranceCases.list, action.payload];
      }
    },
    setParams: (state, action:PayloadAction<{ params:any }>) => {
      const params = action.payload;
      Object.keys(params).forEach((key:string) => {
        (state as any).insuranceCases.params[key] = (params as any)[key];
      });
    },
    setDuplicates: (state, action:PayloadAction<any>) => {
      state.duplicates.list = action.payload;
    },
    setDuplicatesFilter: (state, action:PayloadAction<{ field:any, value:any }>) => {
      const { field, value } = action.payload;
      (state.duplicates.filter as any)[field] = value;
    },
    // TODO
    resetCasesList: (state:IState) => {
      state['insuranceCases']['list'] = initialState['insuranceCases']['list'];
      state['insuranceCases']['params'] = initialState['insuranceCases']['params'];
      state['insuranceCases']['hasMore'] = initialState['insuranceCases']['hasMore'];
    },
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  },
  extraReducers(builder){
    // Get pending cases
    builder.addCase(getPendingCases.pending, (state) => {
      state.pendingCases = null;
    });
    builder.addCase(getPendingCases.fulfilled, (state, action:PayloadAction<IInsuranceCase[]>) => {
      state.pendingCases = action.payload;
    });
    // Get notification cases
    builder.addCase(getConversationCases.pending, (state) => {
      state.conversationCases = null;
    });
    builder.addCase(getConversationCases.fulfilled, (state, action:PayloadAction<IInsuranceCase[]>) => {
      state.conversationCases = action.payload;
    });
    // Get cases
    builder.addCase(getInsuranceCases.pending, (state, action:any) => {
      const { offset } = action.meta.arg;

      if ( offset === 0 ){
        state.insuranceCases.list = null;
      } else {
        state.insuranceCases.params.offset = offset;
        state.insuranceCases.gettingMore = true;
      }
    })
    builder.addCase(getInsuranceCases.fulfilled, (state, action:PayloadAction<IInsuranceCase[]>) => {
      state.insuranceCases.list = state.insuranceCases.list
        ? [...state.insuranceCases.list, ...action.payload]
        : action.payload
      ;
      state.insuranceCases.hasMore = action.payload.length === state.insuranceCases.params.limit;
      state.insuranceCases.gettingMore = false;
    })
    // Get case
    builder.addCase(getInsuranceCase.pending, (state) => {
      state.insuranceCase = null;
    })
    builder.addCase(getInsuranceCase.fulfilled, (state, action:PayloadAction<IInsuranceCase>) => {
      state.insuranceCase = action.payload;
    })
    // Create case
    builder.addCase(createInsuranceCase.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(createInsuranceCase.fulfilled, (state, action:PayloadAction<IInsuranceCase>) => {
      if ( state.insuranceCases.list && state.insuranceCases.params.status === CaseStatuses.Waiting ){
        state.insuranceCases.list = [action.payload, ...state.insuranceCases.list];
      }
    })

    // Update case
    builder.addCase(updateInsuranceCase.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateInsuranceCase.fulfilled, (state, action:PayloadAction<any>) => {
      const { id } = action.payload;
      if ( state.insuranceCase && state.insuranceCase.id === id ){
        state.insuranceCase = action.payload;
      }
      if ( state.insuranceCases.list ){
        state.insuranceCases.list = state.insuranceCases.list.map((insuranceCase:IInsuranceCase) => {
          if ( insuranceCase.id === id ) return action.payload;
          return insuranceCase;
        })
      }
    })

    // Patch case
    builder.addCase(patchInsuranceCase.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(patchInsuranceCase.fulfilled, (state, action:PayloadAction<any>) => {
      const { id, ...otherData } = action.payload;
      if ( state.insuranceCase && state.insuranceCase.id === id ){
        state.insuranceCase = {...state.insuranceCase, ...otherData};
      }
      if ( state.insuranceCases.list ){
        state.insuranceCases.list = state.insuranceCases.list.map((insuranceCase:IInsuranceCase) => {
          if ( insuranceCase.id === id ) return {...insuranceCase, ...otherData};
          return insuranceCase;
        })
      }
    })
    // Delete case
    builder.addCase(deleteInsuranceCase.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(deleteInsuranceCase.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.insuranceCases.list ){
        state.insuranceCases.list = state.insuranceCases.list.filter((insuranceCase:IInsuranceCase) => insuranceCase.id !== action.payload);
      }
    });
    // Statuses case
    builder.addCase(statusesInsuranceCase.fulfilled, (state, action:PayloadAction<number>) => {
      if ( state.insuranceCases.list ){
        state.insuranceCases.list = state.insuranceCases.list.filter((insuranceCase:IInsuranceCase) => insuranceCase.id !== action.payload);
      }
    });
    // Archive case
    builder.addCase(archiveInsuranceCase.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.insuranceCases.list ){
        state.insuranceCases.list = state.insuranceCases.list.filter((insuranceCase:IInsuranceCase) => insuranceCase.id !== action.payload);
      }
    });
    // Unarchive case
    builder.addCase(unarchiveInsuranceCase.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.insuranceCases.list ){
        state.insuranceCases.list = state.insuranceCases.list.filter((insuranceCase:IInsuranceCase) => insuranceCase.id !== action.payload);
      }
    });
    // Accept case
    builder.addCase(acceptInsuranceCase.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(acceptInsuranceCase.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.insuranceCases.list ){
        state.insuranceCases.list = state.insuranceCases.list.filter((insuranceCase:IInsuranceCase) => insuranceCase.id !== action.payload);
      }
      if ( state.pendingCases ){
        state.pendingCases = state.pendingCases.filter((insuranceCase:IInsuranceCase) => insuranceCase.id !== action.payload);
      }
    })
    // Reject case
    builder.addCase(rejectInsuranceCase.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(rejectInsuranceCase.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.insuranceCases.list ){
        state.insuranceCases.list = state.insuranceCases.list.filter((insuranceCase:IInsuranceCase) => insuranceCase.id !== action.payload);
      }
      if ( state.pendingCases ){
        state.pendingCases = state.pendingCases.filter((insuranceCase:IInsuranceCase) => insuranceCase.id !== action.payload);
      }
    });
    // Transmit case
    builder.addCase(transmitInsuranceCase.pending, (state) => {
      state.loading = true;
    });
    // Create case duplicate
    builder.addCase(createCaseDuplicate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCaseDuplicate.fulfilled, (state, action:PayloadAction<IInsuranceCase>) => {
      if ( state.insuranceCases.list && state.insuranceCases.params.status === CaseStatuses.Open ){
        state.insuranceCases.list = [action.payload, ...state.insuranceCases.list];
      }
    });
    // Get duplicates
    builder.addCase(getDuplicates.pending, (state) => {
      state.duplicates.list = null;
    });
    builder.addCase(getDuplicates.fulfilled, (state, action:PayloadAction<IPageDuplicate[][]>) => {
      // From
      // [
      //   [p1, p2, p3, p4],
      //   ...
      // ]
      // To
      // {
      //   p1: [p2, p3, p4],
      //   p2: [p1, p3, p4],
      //   p3: [p1, p2, p4],
      //   p4: [p1, p2, p3],
      //   ...
      // }

      const duplicates = action.payload.reduce((acc:string[][], cur) => {
        acc = [...acc, cur.map((p) => PageService.toPageId(p.documentId, p.pageNum))]
        return acc;
      }, []);
      const entities = duplicates.reduce((acc:Record<string, string[]>, cur:string[]) => {
        for ( let pageId of cur ){
          acc[pageId] = cur.filter((id:string) => id !== pageId);
        }
        return acc;
      }, {});

      state.duplicates.list = action.payload.reduce((acc, cur) => {
        return { ...acc, [uuidv4()]: cur }
      }, {});
      state.duplicates.entities = entities;
    });
    // Update duplicates
    builder.addCase(updateDuplicates.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateDuplicates.fulfilled, (state, action:PayloadAction<IPageDuplicate[][]>) => {
      const duplicates = action.payload.reduce((acc:string[][], cur) => {
        acc = [...acc, cur.map((p) => PageService.toPageId(p.documentId, p.pageNum))]
        return acc;
      }, []);
      const entities = duplicates.reduce((acc:Record<string, string[]>, cur:string[]) => {
        for ( let pageId of cur ){
          acc[pageId] = cur.filter((id:string) => id !== pageId);
        }
        return acc;
      }, {});

      state.duplicates.list = action.payload.reduce((acc, cur) => {
        return { ...acc, [uuidv4()]: cur }
      }, {});
      state.duplicates.entities = entities;
    });

    builder.addCase(downloadCalendarFile.pending, (state) => {
      state.loading = true;
    })

    // Matcher
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.insuranceCases.gettingMore = false;
        state.loading = false;
      }
    );
  }
});

export const CasesActions = slice.actions;

export default slice.reducer;
