import { FC, Fragment, useEffect, useState } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Types
import FileFormats from 'app/types/FileFormats';
// Models
import IOption from 'app/models/Option';
import { IMyUser } from 'app/models/User';
import { ICollection } from 'app/store/DMSCollections/DMSCollections.models';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getExportTemplates } from 'app/store/ExportTemplates/ExportTemplates.async';
import { getTeams } from 'app/store/Teams/Teams.async';
import { acceptCaseShare, getCaseShareDetails } from 'app/store/CaseShares/CaseShares.async';
// Selectors
import { selectLegacyReportTemplatesEnabled } from 'app/store/Accounts/Accounts.selectors';
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectAccountsAsOptions } from 'app/store/Clients/Clients.selectors';
import { selectCollections } from 'app/store/DMSCollections/DMSCollections.selectors';
import { selectExportTemplatesAsOptions } from 'app/store/ExportTemplates/ExportTemplates.selectors';
import { selectTeamsAsOptions } from 'app/store/Teams/Teams.selectors';
import { selectLoading } from 'app/store/CaseShares/CaseShares.selectors';
// Mui
import { Typography } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import Autocomplete from 'app/components/Mui/AutocompleteV2';
import { Select, Button, LoadingButton } from 'app/components/Mui';
import NotificationPreferences from 'app/components/NotificationPreferences';
// Constants
import { SUPPORTED_FILE_FORMATS } from 'app/App.constants';
// 
import ShareAlert from './ShareAlert';
import DocumentPlacements from './DocumentPlacements';

interface IFormData {
  reportTemplateId: string;
  teamId: number | null;
  clientAccountId: number | null;
  token: string;
  documentPlacements: { name:string; collectioId:number }[];
};

type Props = {
  open: boolean;
  onClose: () => void;
  token: string;
}

const CaseFormDialog:FC<Props> = ({
  // Props
  open, onClose, token
}) => {
  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const legacyReportTemplatesEnabled = useAppSelector(selectLegacyReportTemplatesEnabled);
  const accountsOptions:IOption[] | null = useAppSelector(selectAccountsAsOptions);
  const myUser:IMyUser | null = useAppSelector(selectMyUser);
  const collections:ICollection[] | null = useAppSelector(selectCollections);
  const exportTemplatesOptions:IOption[] | null = useAppSelector(selectExportTemplatesAsOptions);
  const teamsOptions:IOption[] | null = useAppSelector(selectTeamsAsOptions);
  const loading = useAppSelector(selectLoading);

  const [ emails, setEmails ] = useState<string[]>(myUser ? [myUser.email] : []);

  const methods = useForm<IFormData>({
    defaultValues: {
      reportTemplateId: '',
      teamId: null,
      clientAccountId: null,
      documentPlacements: []
    }
  });

  const { control, handleSubmit, setValue } = methods;

  const onSubmit = handleSubmit(async (data:IFormData) => {
    const { reportTemplateId, ...otherData } = data;
    const nextData:any = { ...otherData, token };

    if ( legacyReportTemplatesEnabled && reportTemplateId ) nextData['reportTemplateId'] = Number(reportTemplateId);

    if ( emails.length ) nextData['notificationPreferences'] = { emails };

    try {
      await dispatch(acceptCaseShare(nextData)).unwrap();
      onClose();
    } catch(error){}
  });

  useEffect(() => {
    dispatch(getExportTemplates({}));
    dispatch(getTeams({}));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( !collections ) return;

    const asyncGetCaseShareDetails = async () => {
      try {
        const response = await dispatch(getCaseShareDetails(token)).unwrap();
        if ( response.documents ) setValue('documentPlacements', response.documents.map((d:any) => {
          const nextDocument = { ...d, collectionId: '' };
          if ( SUPPORTED_FILE_FORMATS.includes(d.fileFormat) ){
            if ( d.fileFormat && collections && collections.length ){
              let collectionId:string = '';
              for ( let collection of collections ){
                if ( typeof d.ocred !== 'undefined' && collection.applyOcr !== d.ocred ) continue;
                if (
                  !collection.fileFormats ||
                  !collection.fileFormats.length ||
                  collection.fileFormats.includes(d.fileFormat as FileFormats)
                ){
                  if ( !collectionId ){
                    collectionId = collection.id.toString();
                  } else {
                    collectionId = '';
                    break;
                  }
                }
              }
              nextDocument['collectionId'] = collectionId;
            }
          }
          return nextDocument;
        }));
      } catch(error){}
    }

    asyncGetCaseShareDetails();
    // eslint-disable-next-line
  }, [collections]);

  const actions = (
    <Fragment>
      <Button
        name="Cancel share case form dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name="Submit share case form dialog"
        loading={loading}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{t('labels.share')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={`${t('labels.share')} ${t('labels.case')}`}
      actions={actions}
    >
      <FormProvider {...methods}>
        <form noValidate>
          <ShareAlert />

          {/* Report template */}
          {legacyReportTemplatesEnabled ? (
            <Controller
              control={control} name="reportTemplateId"
              render={({ field }) => (
                <Select
                  {...field}
                  label={t('labels.reportTemplate')}
                  options={exportTemplatesOptions}
                />
              )}
            />
          ) : null}
          <Controller
            control={control} name="teamId"
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={teamsOptions || []}
                InputProps={{
                  label: t('labels.team')
                }}
                renderOption={(props, option) => (
                  <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.id}>
                    <Typography variant="subtitle1">{option.name}</Typography>
                    {option.subname ? (
                      <Typography variant="caption">{option.subname}</Typography>
                    ) : null}
                  </li>
                )}
              />
            )}
          />
          {/* Client account */}
          <Controller
            control={control} name="clientAccountId"
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={accountsOptions || []}
                InputProps={{
                  label: t('labels.clientAccount')
                }}
                renderOption={(props, option) => (
                  <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.id}>
                    <Typography variant="subtitle1">{option.name}</Typography>
                    {option.props?.fullAddress ? (
                      <Typography variant="caption">{option.props.fullAddress}</Typography>
                    ) : null}
                  </li>
                )}

                disableClearable={true}
              />
            )}
          />

          <NotificationPreferences
            emails={emails}
            onSetEmails={setEmails}
          />

          <DocumentPlacements />
        </form>
      </FormProvider>
    </Dialog>
  )
}

export default CaseFormDialog;