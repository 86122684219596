import { Middleware } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
import MixpanelTracks from "app/types/MixpanelTracks";
// Models
import IEpisodeAuthor from "app/models/EpisodeAuthor";
// Async
import { createAuthor, updateAuthor } from 'app/store/EpisodeAuthors/EpisodeAuthors.async';
// Services
import Mixpanel from "app/services/Mixpanel.service";

const EpisodeAuthorsMiddleware:Middleware = () => (next:any) => (action:any) => {
  const { type, payload } = action;

  if ( (type as string).startsWith(Reducers.EpisodeAuthors) ){
    if ( type === createAuthor.fulfilled.type ){
      const author = payload as IEpisodeAuthor;
  
      Mixpanel.track(MixpanelTracks.AuthorCreate, {
        authorId: author.id
      });
    }
  
    if ( type === updateAuthor.fulfilled.type ){
      const author = payload as IEpisodeAuthor;
  
      Mixpanel.track(MixpanelTracks.AuthorUpdate, {
        authorId: author.id
      });
    }
  }

  return next(action);
}

export default EpisodeAuthorsMiddleware;
