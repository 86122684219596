import { FC, useEffect, useMemo, useState } from 'react';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
// Types
import UserPermissions from 'app/types/Permissions';
import UserRoles from 'app/types/UserRoles';
import CaseStatuses from 'app/types/CaseStatuses';
// Models
import { RootState } from 'app/store';
import { IMyUser } from 'app/models/User';
import IInsuranceCase from 'app/models/Case';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectMyUser, selectMyUserHasPermission } from 'app/store/Users/Users.selectors';
import { selectCaseReportTemplateSelected, selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
// Mui
import { Theme, Tabs, Tooltip } from '@mui/material';
// Components
import { Tab } from 'app/components/Mui';
import { selectAIChatEnabled, selectInvoicingEnabled, selectLegacyReportTemplatesEnabled } from 'app/store/Accounts/Accounts.selectors';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
// i18next
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
interface ILink {
  value: string,
  to: string,
  visible?: boolean,
  disabled?: boolean,
  label: string,
  hint?: string,
}

const CaseDetailsNavs:FC = () => {
  const { t } = useTranslation('common');

  const { url } = useRouteMatch();
  const { pathname } = useLocation<{ pathname:string }>();
  // State
  const trialMode = useAppSelector(selectTrialMode);
  const invoicingEnabled = useAppSelector(selectInvoicingEnabled);
  const aiChatEnabled = useAppSelector(selectAIChatEnabled);
  const legacyReportTemplatesEnabled = useAppSelector(selectLegacyReportTemplatesEnabled);
  const myUser:IMyUser | null = useAppSelector(selectMyUser);
  const myUserInvoiceViewEnabled = useAppSelector((state:RootState) => selectMyUserHasPermission(state, {
    permission: UserPermissions.InvoiceView
  }));
  const insuranceCase:IInsuranceCase | null = useAppSelector(selectInsuranceCase);
  const caseReportTemplateSelected = useAppSelector(selectCaseReportTemplateSelected);

  const lastDocument = localStorage.getItem('shimlaw_ep:last_document') || `{}`;
  const parsed = JSON.parse(lastDocument);
  const lastDocumentId = insuranceCase?.id ? parsed[insuranceCase.id] : null;

  const [ value, setValue ] = useState<string | boolean>('documents');

  const links:ILink[] = useMemo(() => {
    const isCaseStatusOpen = insuranceCase?.status === CaseStatuses.Open;
    const isCaseStatisArchive = insuranceCase?.status === CaseStatuses.Archived;
    const isCaseProcessingStatusReady = insuranceCase?.processing?.status === 'ready';

    const isDuplicatesDisabled = !(isCaseProcessingStatusReady && (isCaseStatusOpen || isCaseStatisArchive));
    const isInvoicesVisible = !trialMode && ((myUser?.role === UserRoles.Staff && myUserInvoiceViewEnabled) || invoicingEnabled);
    const isReportSectionsVisible = !trialMode && legacyReportTemplatesEnabled && caseReportTemplateSelected;
    const isAIChatVisible = !trialMode && aiChatEnabled;

    return [
      {
        value: 'documents',
        to: `documents${lastDocumentId ? `/${lastDocumentId}` : ''}`,
        visible: !trialMode,
        label: t('pages.staffPages.caseDetailsPage.files')
      }, {
        value: 'workspace',
        to: 'workspace',
        label: t('pages.staffPages.caseDetailsPage.workspace')
      }, {
        value: 'duplicates',
        to: 'duplicates',
        visible: !trialMode,
        disabled: isDuplicatesDisabled,
        label: t('pages.staffPages.caseDetailsPage.duplicates'),
        hint: isDuplicatesDisabled ? t('pages.staffPages.caseDetailsPage.waitForCaseToBeProcessed') : ''
      }, {
        value: 'calendar',
        to: 'calendar',
        label: t('pages.staffPages.caseDetailsPage.calendar')
      }, {
        value: 'reports',
        to: 'reports',
        visible: !trialMode,
        label: t('pages.staffPages.caseDetailsPage.reports')
      }, {
        value: 'report-sections',
        to: 'report-sections',
        visible: isReportSectionsVisible,
        label: t('pages.staffPages.caseDetailsPage.reportSections')
      }, {
        value: 'messages',
        to: 'messages',
        visible: !trialMode,
        label: t('pages.staffPages.caseDetailsPage.messages')
      }, {
        value: 'invoices',
        to: 'invoices',
        visible: isInvoicesVisible,
        label: t('pages.staffPages.caseDetailsPage.invoices')
      }, {
        value: 'search',
        to: 'search',
        visible: !trialMode,
        label: t('pages.staffPages.caseDetailsPage.search')
      }, {
        value: 'templates',
        to: 'templates',
        visible: !trialMode,
        label: t('pages.staffPages.caseDetailsPage.templates')
      }, {
        value: 'ai-chat',
        to: 'ai-chat',
        visible: isAIChatVisible,
        label: 'AI Chat'
      }
    ];
    // eslint-disable-next-line
  }, [i18n.language, trialMode, lastDocumentId, insuranceCase, myUser, invoicingEnabled, aiChatEnabled, legacyReportTemplatesEnabled]);

  useEffect(() => {
    const activePath = pathname.split('/')[4];

    const foundedLink = links.find((link:any) => activePath === link.value);

    setValue(foundedLink?.value || false);
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <Tabs
      sx={{ flexGrow: 1 }}
      value={value}
      variant="scrollable"
      TabScrollButtonProps={{
        sx: {
          '&.MuiTabs-scrollButtons': {
            '&:hover': {
              bgcolor: (theme:Theme) => theme.palette.primary.light
            },
            '&.Mui-disabled': {
              opacity: 0.25
            }
          }
        }
      }}
    >
      {links.map(link => {
        if ( typeof link.visible !== 'undefined' && !link.visible ) return null;
        return (
          <Tab
            key={`nav item ${link.to}`}
            name={link.to}
            sx={{
              minWidth: 'initial',
              minHeight: { xs: 56, sm: 63 },
              fontSize: 16,
              px: 3,
              textTransform: 'capitalize',
              pointerEvents: 'auto !important',
              '&:hover': {
                background: link.disabled ? 'none' : 'auto',
                color: link.disabled ? 'rgba(0, 0, 0, 0.38)' : 'auto',
              }
            }}
            component={!link.disabled ? Link : undefined}
            to={`${url}/${link.to}`}
            value={link.value}
            label={(
              <Tooltip title={link.hint}>
                <span>{link.label}</span>
              </Tooltip>
            )}
            disabled={typeof link.disabled !== 'undefined' ? link.disabled : false}
          />
        )
      })}
    </Tabs>
  )
}

export default CaseDetailsNavs;
