import { FC, Fragment, useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Dayjs } from 'dayjs';
// Models
import IInsuranceCase from 'app/models/Case';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getInsuranceCase, updateInsuranceCase } from 'app/store/Cases/Cases.async';
// Selectors
import { selectHcpcsCodesEnabled, selectLegacyReportTemplatesEnabled } from 'app/store/Accounts/Accounts.selectors';
import { selectInsuranceCase, selectLoading } from 'app/store/Cases/Cases.selectors';
// Mui
import {
  Box,
  Typography, Skeleton,
  Paper, Tabs, Tab, Tooltip
} from '@mui/material';
// Icons
import { Error as ErrorIcon } from '@mui/icons-material';
// Components
import Dialog from 'app/components/Dialog';
import ClientDetails from 'app/components/ClientDetails';
import NotificationPreferences from 'app/components/NotificationPreferences';
import { Button, LoadingButton } from 'app/components/Mui';
// 
import CaseDetails from './CaseTabContent';
import OpposingDetails from './OpposingDetailsTabContent';
// i18next
import { useTranslation } from 'react-i18next';
import ClaimantTabContent from './ClaimantTabContent';
import { convertDateToString, convertTimeToString, parseDate, parseTime } from 'app/utilities/DateTime';
import ClientTabContent from './ClientTabContent';

interface IFormData {
  name: string;
  reportTemplateId: string | number;
  teamId: string | number;
  examinationDate: Dayjs | null;
  trialDate: Dayjs | null;
  settings: {
    medicareOverchargeThreshold: number;
  };
  opposingDetails: {
    opposingCounsel: {
      name: string;
      email: string;
      phone: string;
    },
    caseNumber: string;
    courtJurisdiction: string;
    notes: string;
  };
  claimant: {
    name: string;
    dateOfBirth: Dayjs | null;
  };
  eventDate: Dayjs | null,
  eventTime: Dayjs | null;
  claimNumber: string;
}

type Props = {
  open: boolean;
  onClose: () => void;
  insuranceCaseId?: number;
  disabled?: boolean;
};

const CaseDetailsDialog:FC<Props> = ({
  // Props
  open, onClose, insuranceCaseId, disabled = false,
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const hcpcsCodesEnabled:boolean = useAppSelector(selectHcpcsCodesEnabled);
  const legacyReportTemplatesEnabled = useAppSelector(selectLegacyReportTemplatesEnabled);
  const insuranceCase:IInsuranceCase | null = useAppSelector(selectInsuranceCase);
  const loading:boolean = useAppSelector(selectLoading);

  const [ emails, setEmails ] = useState<string[]>([]);
  const [ activeTab, setActiveTab ] = useState(0);
  const [ caseTabError, setCaseTabError ] = useState(false);
  const [ claimantTabError, setClaimantTabError ] = useState(false);

  const methods = useForm<IFormData>({
    defaultValues: {
      name: '',
      reportTemplateId: '',
      teamId: '',
      examinationDate: null,
      trialDate: null,
      settings: {
        medicareOverchargeThreshold: 0
      },
      opposingDetails: {
        opposingCounsel: {
          name: '',
          email: '',
          phone: ''
        },
        caseNumber: '',
        courtJurisdiction: '',
        notes: ''
      },
      claimant: {
        name: '',
        dateOfBirth: null,
      },
      eventDate: null,
      eventTime: null,
      claimNumber: ''
    }
  });

  const { reset, handleSubmit } = methods;

  const onSubmit = handleSubmit((data:any) => {
    if ( !insuranceCase ) return;

    const { reportTemplateId, teamId, examinationDate, trialDate, opposingDetails, settings, claimant, eventDate, eventTime, claimNumber, ...otherData } = data;
    const nextData:any = {
      ...otherData,
      opposingDetails: {
        opposingCounsel: {}
      },
      eventDate: convertDateToString(eventDate),
      claimant: {
        name: claimant.name
      },
      version: insuranceCase.version,
      settings: {
        aiChatEnabled: insuranceCase.settings?.aiChatEnabled || false
      }
    };

    if ( claimant.dateOfBirth ) nextData['claimant']['dateOfBirth'] = convertDateToString(claimant.dateOfBirth);

    if ( teamId ) nextData['teamId'] = Number(teamId);

    if ( claimNumber ) nextData['claimNumber'] = claimNumber;

    if ( eventTime ) nextData['eventTime'] = convertTimeToString(eventTime)

    if ( legacyReportTemplatesEnabled && reportTemplateId ){
      nextData['reportTemplateId'] = Number(reportTemplateId);
    }
    if ( hcpcsCodesEnabled ) {
      nextData['settings']['medicareOverchargeThreshold'] = Number(settings.medicareOverchargeThreshold);
    }

    if ( insuranceCase.notes ) nextData['notes'] = insuranceCase.notes;
    if ( insuranceCase.labels && insuranceCase.labels.length ) nextData['labels'] = insuranceCase.labels;

    if ( examinationDate ) nextData['examinationDate'] = convertDateToString(examinationDate);
    if ( trialDate ) nextData['trialDate'] = convertDateToString(trialDate);
    if ( emails.length ) nextData['notificationPreferences'] = { emails };

    const { opposingCounsel, caseNumber, courtJurisdiction, notes } = opposingDetails;

    if ( caseNumber ) nextData.opposingDetails['caseNumber'] = caseNumber;
    if ( courtJurisdiction ) nextData.opposingDetails['courtJurisdiction'] = courtJurisdiction;
    if ( notes ) nextData.opposingDetails['notes'] = notes;

    Object.keys(opposingCounsel).forEach((key) => {
      if ( opposingCounsel[key] ) nextData.opposingDetails.opposingCounsel[key] = opposingCounsel[key];
    });

    asyncUpdateInsuranceCase({ insuranceCaseId: insuranceCase.id, data: nextData });
  })

  const asyncUpdateInsuranceCase = async (data:any) => {
    try {
      await dispatch(updateInsuranceCase(data)).unwrap();
      onClose();
    } catch(error){}
  }

  const handleChange = (_:any, nextTab:number) => setActiveTab(nextTab);

  useEffect(() => {
    if ( insuranceCaseId ) dispatch(getInsuranceCase(insuranceCaseId));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( !insuranceCase ) return;

    const { team, examinationDate, trialDate, claimant, eventDate, eventTime, opposingDetails, claimNumber = '', ...otherCase } = insuranceCase;

    reset({
      ...otherCase,
      claimNumber,
      teamId: team?.id || '',
      examinationDate: examinationDate ? parseDate(examinationDate) : null,
      trialDate: trialDate ? parseDate(trialDate) : null,
      claimant: {
        name: claimant.name,
        dateOfBirth: claimant.dateOfBirth ? parseDate(claimant.dateOfBirth) : null,
      },
      eventDate: eventDate ? parseDate(eventDate) : null,
      eventTime: eventTime ? parseTime(eventTime) : null,
      opposingDetails: {
        opposingCounsel: {
          name: opposingDetails?.opposingCounsel?.name || '',
          email: opposingDetails?.opposingCounsel?.email || '',
          phone: opposingDetails?.opposingCounsel?.phone || ''
        },
        caseNumber: opposingDetails?.caseNumber || '',
        courtJurisdiction: opposingDetails?.courtJurisdiction || '',
        notes: opposingDetails?.notes || ''
      }
    });

    setEmails(insuranceCase.notificationPreferences?.emails || []);
    // eslint-disable-next-line
  }, [insuranceCase]);

  const errorElem = (
    <Tooltip title={t('validations.hasRequiredFields')}><ErrorIcon color="error" /></Tooltip>
  );

  const actions = (
    <Fragment>
      <Button
        name="Close case details dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      {!disabled && (
        <LoadingButton
          name="Save case details dialog"
          loading={loading}
          type="submit"
          variant="contained"
          color="primary"
        >{t('labels.save')}</LoadingButton>
      )}
    </Fragment>
  );

  return (
    <FormProvider {...methods}>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        title={t('labels.caseDetails')}
        actions={actions}

        PaperProps={{
          component: 'form',
          onSubmit,
          noValidate: true
        }}
      >
        {insuranceCase ? (
          <Fragment>
            <Paper variant="outlined">
              <Tabs
                value={activeTab}
                onChange={handleChange}
                centered
              >
                <Tab label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {'Case'}
                    {caseTabError ? errorElem : null}
                  </Box>
                }/>
                <Tab label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {t('dialogs.caseDetails.opposingDetails')}
                    {claimantTabError ? errorElem : null}
                  </Box>
                } />
                <Tab label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {'Claimant'}
                    {claimantTabError ? errorElem : null}
                  </Box>
                } />
                <Tab label="Client" />
              </Tabs>
            </Paper>
            <Box sx={{ display: activeTab === 0 ? 'block' : 'none' }}>
              <CaseDetails onSetError={setCaseTabError} disabled={disabled} />
              <NotificationPreferences
                emails={emails}
                onSetEmails={setEmails}
                disabled={disabled}
              />
            </Box>
            <Box sx={{ display: activeTab === 1 ? 'block' : 'none' }}>
              <OpposingDetails onSetError={setClaimantTabError} disabled={disabled} />
            </Box>
            <Box sx={{ display: activeTab === 2 ? 'block' : 'none' }}>
              <ClaimantTabContent onSetError={setClaimantTabError} />
            </Box>
            <Box sx={{ display: activeTab === 3 ? 'block' : 'none' }}>
              <ClientTabContent />
              <ClientDetails showClaimant={false} showClientAccount={false} insuranceCase={insuranceCase} />
            </Box>
          </Fragment>
        ) : (
          <Fragment>
            <Typography component="div" variant="body1">
              <Skeleton width="60%" />
            </Typography>
            <Typography component="div" variant="caption">
              <Skeleton />
            </Typography>
            <Typography component="div" variant="caption">
              <Skeleton />
            </Typography>
          </Fragment>
        )}
      </Dialog>
    </FormProvider>
  )
}

export default CaseDetailsDialog;