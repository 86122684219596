import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import IInsuranceCase from 'app/models/Case';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { patchInsuranceCase } from 'app/store/Cases/Cases.async';
// Actions
import { CasesActions } from 'app/store/Cases/Cases.slice';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectHasAnyDocumentOcred } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Mui
import { Box, Alert, Typography } from '@mui/material';
// Components
import { LoadingButton } from 'app/components/Mui';
import { Message } from 'app/components/Utilities';
// Hooks
import useCasePermissionResolved from 'app/hooks/useCasePermissionResolver';
// 
import ConversationInitialPrompts from './ConversationInitialPrompts';

const ConversationInitialRoute:FC = () => {
  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;
  const hasAnyDocumentOcred = useAppSelector(selectHasAnyDocumentOcred);

  const { caseAIChatEnabled, isCaseProcessingReadyOrNotStarted, isCaseProcessingStatusNotReady } = useCasePermissionResolved();

  const [ loading, setLoading ] = useState(false);

  const handleClick = async () => {
    setLoading(true);

    try {
      await dispatch(patchInsuranceCase({
        insuranceCaseId: insuranceCase.id,
        data: {
          version: insuranceCase.version,
          settings: {
            ...(insuranceCase.settings || {}),
            aiChatEnabled: true
          }
        },
        message: 'AI Chat is enabled'
      })).unwrap();

      dispatch(CasesActions.setInsuranceCaseProcessingStatus('processing'));

    } catch (error){
      console.error(error)
    } finally {
      setLoading(false);
    }
  }

  const renderProcessedDocumentsAlert = isCaseProcessingReadyOrNotStarted && !hasAnyDocumentOcred ? (
    <Box sx={{ maxWidth: 888, p: 4, mx: 'auto' }}>
      <Alert color="warning">{t('hints.aiChatProcessedDocumentsAlert')}</Alert>
    </Box>
  ) : null;

  if ( !caseAIChatEnabled ) return (
    <Box sx={{
      maxWidth: 888,
      p: 4,
      mx: 'auto'
    }}>
      <Typography sx={{ fontWeight: 700 }} variant="subtitle1">{t('hints.aiChatEnableSubtitle1')}</Typography>
      <Typography sx={{ mb: 4, fontWeight: 700 }} variant="subtitle1">{t('hints.aiChatEnableSubtitle2')}</Typography>
      <Typography sx={{ color: 'rgba(0,0,0,0.54)' }} display="block" variant="body1">{t('hints.aiChatEnableDescription1')}</Typography>
      <Typography sx={{ color: 'rgba(0,0,0,0.54)' }} display="block" variant="body1">{t('hints.aiChatEnableDescription2')}</Typography>
      <Typography sx={{ color: 'rgba(0,0,0,0.54)' }} display="block" variant="body1">{t('hints.aiChatEnableDescription3')}</Typography>
      <Typography sx={{ color: 'rgba(0,0,0,0.54)' }} display="block" variant="body1">{t('hints.aiChatEnableDescription4')}</Typography>
      {isCaseProcessingStatusNotReady ? (
        <Typography sx={{ color: 'rgba(0,0,0,0.54)', fontWeight: '700' }} display="block" variant="body1">{t('hints.aiChatEnableDescription5')}</Typography>
      ) : null}
      <Box sx={{ pt: 4, textAlign: 'center' }}>
        <LoadingButton
          name="Enable AI Chat"
          loading={loading}
          disabled={isCaseProcessingStatusNotReady}
          onClick={handleClick}
          variant="contained"
        >{t('labels.enableAIChat')}</LoadingButton>
      </Box>
    </Box>
  );
  if ( isCaseProcessingStatusNotReady ) return (
    <Message text={t('hints.aiChatEnableDescription6')} />
  );
  return (
    <Fragment>
      {renderProcessedDocumentsAlert}
      {hasAnyDocumentOcred ? (
        <Message text={t('pages.staffPages.caseDetailsPage.initialConversationText')} />
      ) : null}
      <ConversationInitialPrompts />
    </Fragment>
  );
}

export default ConversationInitialRoute;
