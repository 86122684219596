import { MiddlewareAPI, Middleware, isRejectedWithValue } from '@reduxjs/toolkit'
// Types
import BodyErrors from 'app/types/BodyErrors';
import NotificationStatuses from "app/types/NotificationStatuses";
// Models
import { AppDispatch } from 'app/store';
// Actions
import { AppUIStatusActions } from 'app/store/AppUIStatus/AppUIStatus.slice';
import { AppUiNotificationsActions } from "app/store/AppUINotifications/AppUINotifications.slice";
import { urlPathMatches } from 'app/utilities/Utilities';
// Selectors
import { selectAuthenticated } from 'app/store/Auth/Auth.selectors';
// Constants
import { DIALOG_RESPONSE_ERRORS, IGNORE_RESPONSE_ERRORS, SNACKBAR_RESPONSE_ERRORS } from 'app/App.constants';

const RejectHandlerMiddleware:Middleware = ({ dispatch, getState }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  if ( isRejectedWithValue(action) ){
    const state = getState();
    const authenticated = selectAuthenticated(state);

    if ( typeof action.payload !== 'undefined' ){
      const { error, errors, statusCode, message, path, method } = action.payload;

      if ( skipErrorResponse(statusCode, error, method, path) ){
        return next(action);
      } else if ( displayErrorSnackbar(statusCode, error, method, path) ){
        if ( errors && errors.length ){
          for ( let error of errors ){
            dispatch(AppUiNotificationsActions.addSnackbar({
              message: error.message,
              options: { variant: NotificationStatuses.Error }
            }));
          }
        } else if ( message ){
          dispatch(AppUiNotificationsActions.addSnackbar({
            message,
            options: { variant: NotificationStatuses.Error }
          }));
        }
      } else if ( displayErrorDialog(statusCode, authenticated) ){
        dispatch(AppUIStatusActions.setRequestStatusCode(statusCode));
      }
    } else {
      dispatch(AppUiNotificationsActions.addSnackbar({
        message: 'Something went wrong',
        options: { variant: NotificationStatuses.Error },
      }));
    }
  }
  return next(action);
}

export default RejectHandlerMiddleware;

const skipErrorResponse = (statusCode:number, error:BodyErrors, method:string, path:string) => {
  const errorEntity = (IGNORE_RESPONSE_ERRORS as any)[statusCode];
  if ( typeof errorEntity === 'undefined' ) return false;
  return (
    (statusCode === 401 && errorEntity.errorTypes) ||
    (statusCode === 403 && errorEntity.errorTypes && errorEntity.errorTypes.includes(error)) ||
    (statusCode === 409 && errorEntity.pathRegExps && pathMatchesAnyRegExp(errorEntity.pathRegExps, method, path)) ||
    (statusCode === 503 && errorEntity.pathRegExps && pathMatchesAnyRegExp(errorEntity.pathRegExps, method, path))
  );
}

const displayErrorSnackbar = (statusCode:number, error:BodyErrors, method:string, path:string) => {
  const errorEntity = (SNACKBAR_RESPONSE_ERRORS as any)[statusCode];
  if ( typeof errorEntity === 'undefined' ) return false;
  return (
    (statusCode === 400) ||
    (statusCode === 401 && errorEntity.errorTypes && errorEntity.errorTypes.includes(error)) ||
    (statusCode === 409) ||
    (statusCode === 500 && errorEntity.pathRegExps && pathMatchesAnyRegExp(errorEntity.pathRegExps, method, path))
  );
}

const displayErrorDialog = (statusCode:number, authenticated:boolean | undefined) => {
  if ( !authenticated ) return false; // Do not show error dialog during multistep login routine ( ie. account selection )
  const errorEntity = (DIALOG_RESPONSE_ERRORS as any)[statusCode];
  return typeof errorEntity !== 'undefined' && !errorEntity.errorTypes;
}

const pathMatchesAnyRegExp = (pathRegExps:RegExp[], method:string, path:string) => {
  let result = false;
  for ( let pathRegExp of pathRegExps ){
    if ( urlPathMatches(pathRegExp, method, path) ){
      result = true;
      break;
    }
  }
  return result;
}