import { ChangeEvent, FC, useState, useCallback, useEffect, useMemo } from 'react';
import { debounce } from 'throttle-debounce';
import { useTranslation } from 'react-i18next';
// Models
import { ICurrentAccount } from 'app/models/Account';
import { IPrompt } from 'app/store/AIChatPrompts/AIChatPrompts.models';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { AIChatPromptsActions } from 'app/store/AIChatPrompts/AIChatPrompts.slice';
// Selectors
import { selectCurrentAccount } from 'app/store/Accounts/Accounts.selectors';
import { selectFilter, selectFilteredPrompts } from 'app/store/AIChatPrompts/AIChatPrompts.selectors';
// Mui
import { Theme, Box, Tabs, Tab } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import DataLoading from 'app/components/DataLoading';
import AIPromptCard from 'app/components/AIPromptCard';
import { Input } from 'app/components/Mui';

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: (aiPrompt:IPrompt) => void;
}

const AIPromptSelectDialog:FC<Props> = ({
  // Props
  open, onClose, onConfirm
}) => {
  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const currentAccount = useAppSelector(selectCurrentAccount) as ICurrentAccount;
  const prompts = useAppSelector(selectFilteredPrompts);
  const filter = useAppSelector(selectFilter);

  const data = useMemo(() => {
    if ( !prompts ) return null
    return prompts.reduce((acc:{ platformPrompts:IPrompt[], regularPrompts:IPrompt[] }, cur:IPrompt) => {
      if ( cur.platform ){
        acc.platformPrompts.push(cur);
      } else {
        acc.regularPrompts.push(cur);
      }
      return acc;
    }, { platformPrompts: [], regularPrompts:[] });
    // eslint-disable-next-line
  }, [prompts])

  const [ stateFilter, setStateFilter ] = useState(filter);
  const [ tab, setTab ] = useState(0);

  useEffect(() => {
    return () => {
      dispatch(AIChatPromptsActions.setInitialField('filter'));
    }
    // eslint-disable-next-line
  }, [])

  // eslint-disable-next-line
  const debounceFilter = useCallback(debounce(500, (field:keyof typeof filter, value:any) => {
    dispatch(AIChatPromptsActions.setFilter({ field, value }))
  }), []);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStateFilter((prevState:any) => ({
      ...prevState,
      [name]: value
    }));
    debounceFilter(name as keyof typeof filter, value);
  }

  const handleClick = (aiPrompt:IPrompt) => () => {
    onConfirm(aiPrompt);
    onClose();
  }

  const handleChangeTab = (_:any, nextTab:number) => {
    setTab(nextTab);
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title="Prompt Hub"
    >
      <Box sx={{ display: 'flex', mb: 4 }}>
        <Input
          label={t('labels.search')} id="search" name="search"
          placeholder="Name or label"
          value={stateFilter.search}
          onChange={handleChange}
          size="small"
          margin="none"
        />
      </Box>
      <Tabs
        sx={{
          borderBottom: (theme:Theme) => `1px solid ${theme.palette.divider}`,
          mb: 4
        }}
        value={tab}
        onChange={handleChangeTab}
        centered
      >
        <Tab sx={{ textTransform: 'capitalize' }} label={currentAccount.company} />
        <Tab sx={{ textTransform: 'capitalize' }} label="Case Chronology" />
      </Tabs>
      <Box sx={{ display: tab === 0 ? 'block' : 'none', height: 600 }}>
        <DataLoading
          data={data?.regularPrompts}
          text={t("pages.adminPages.aiPromptHubPage.noDataText")}
        >{(data) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            {data.map((aiPrompt) => (
              <AIPromptCard
                key={`ai prompt item ${aiPrompt.id}`}
                aiPrompt={aiPrompt}
                onClick={handleClick(aiPrompt)}
              />
            ))}
          </Box>
        )}</DataLoading>
      </Box>
      <Box sx={{ display: tab === 1 ? 'block' : 'none', height: 600 }}>
        <DataLoading
          data={data?.platformPrompts}
          text={t("pages.adminPages.aiPromptHubPage.noDataText")}
        >{(data) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            {data.map((aiPrompt) => (
              <AIPromptCard
                key={`ai prompt item ${aiPrompt.id}`}
                aiPrompt={aiPrompt}
                onClick={handleClick(aiPrompt)}
              />
            ))}
          </Box>
        )}</DataLoading>
      </Box>
    </Dialog>
  )
}

export default AIPromptSelectDialog;
