// Types
import Colors from "app/types/Colors";
import { StepTypes, StepTasks } from "app/store/AutomatedWorkflows/AutomatedWorkflows.types";
// Models
import { RootState } from "app/store";
// Store
import { useAppSelector } from "app/hooks/useStore";
// Selectors
import { selectCollectionsAsOptionsByFileType } from "app/store/DMSCollections/DMSCollections.selectors";
// Utils
import generateShortId from 'app/utilities/shortIds';

const useWorkflowUtils = () => {
  // ToDO: State
  const ocredCollectionOptions = useAppSelector((state:RootState) => selectCollectionsAsOptionsByFileType(state, {
    ocred: true
  }));
  const customCollectionOptions = useAppSelector((state:RootState) => selectCollectionsAsOptionsByFileType(state, {
    fileFormat: 'custom'
  }));

  // Input objects
  const runPromptInput = {
    parameters: {
      promptId: '', // required
      coverage: ''
    }
  };

  const createDocumentInput = {
    parameters: {
      name: '',
      collectionId: customCollectionOptions && customCollectionOptions[0]
        ? customCollectionOptions[0].id.toString()
        : ''
      ,
      labels: [
        { name: 'Automation', color: Colors.Red }
      ]
    }
  };

  const createReportInput = {
    parameters: {
      name: ''
    }
  };

  const sendEmailInput = {
    parameters: {
      to: '',
      subject: ''
    }
  };

  const getInitialWorkflowConditions = () => {
    return {
      'document:collectionId': ocredCollectionOptions && ocredCollectionOptions[0]
        ? [`${ocredCollectionOptions[0].id}`]
        : []
      ,
      'case:labels': ['Automation']
    }
  };

  const getInitialWorkflowSteps = () => {
    const runPromptId = generateShortId();
    const createDocumentId = generateShortId();
    const runPromptIdSecond = generateShortId();
    const createDocumentIdSecond = generateShortId();
    const createReportId = generateShortId();
    return [
      {
        id: runPromptId,
        type: StepTypes.Task,
        task: StepTasks.RunPrompt,
        input: runPromptInput
      },
      {
        id: createDocumentId,
        type: StepTypes.Task,
        task: StepTasks.CreateDocument,
        dependsOn: [runPromptId],
        input: createDocumentInput
      },
      {
        id: runPromptIdSecond,
        type: StepTypes.Task,
        task: StepTasks.RunPrompt,
        input: runPromptInput
      },
      {
        id: createDocumentIdSecond,
        type: StepTypes.Task,
        task: StepTasks.CreateDocument,
        dependsOn: [runPromptIdSecond],
        input: createDocumentInput
      },
      {
        id: createReportId,
        type: StepTypes.Task,
        task: StepTasks.CreateReport,
        dependsOn: [createDocumentId, createDocumentIdSecond],
        input: createReportInput
      }
    ];
  }

  const generateStepsGroup = () => {
    const runPromptId = generateShortId();
    const createDocumentId = generateShortId();
    return [
      {
        id: runPromptId,
        type: StepTypes.Task,
        task: StepTasks.RunPrompt,
        input: runPromptInput
      },{
        id: createDocumentId,
        type: StepTypes.Task,
        task: StepTasks.CreateDocument,
        dependsOn: [runPromptId],
        input: createDocumentInput
      }
    ];
  }

  const generateStepSendEmail = (dependsOn:string[]) => {
    const id = generateShortId();
    return {
      id,
      type: StepTypes.Task,
      task: StepTasks.SendEmail,
      input: sendEmailInput,
      dependsOn
    };
  }

  return {
    getInitialWorkflowConditions,
    getInitialWorkflowSteps,
    generateStepsGroup,
    generateStepSendEmail
  };
}

export default useWorkflowUtils;