import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Types
import { WorkflowType } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.types';
// Mui
import { Backdrop, SpeedDial, SpeedDialAction } from '@mui/material';
// Icons
import {
  HdrAuto as HdrAutoIcon,
  FlashOn as FlashOnIcon
} from '@mui/icons-material';
// Hooks
import useToggle from 'app/hooks/useToggle';
//
import WorkflowFormDialog from './WorkflowFormDialog';


const AutomatedWorkflowsFab:FC = () => {
  const { t } = useTranslation('common');

  const actions = useMemo(() => ([
    { icon: <HdrAutoIcon />, name: t('pages.adminPages.automatedWorkflowsPage.speedDialActionAutomated'), workflowType: WorkflowType.Automated },
    { icon: <FlashOnIcon />, name: t('pages.adminPages.automatedWorkflowsPage.speedDialActionInstant'), workflowType: WorkflowType.Instant }
    // eslint-disable-next-line
  ]), []);

  const { open, toggle } = useToggle();

  const [ selectedWorkflowType, setSelectedWorkflowType ] = useState<WorkflowType | undefined>(undefined);

  const [ openSpeedDial, setOpenSpeedDial ] = useState(false);

  const handleOpenSpeedDial = () => {
    // If remove check on `openSpeedDial` it will show it once more, aftre dialog close
    if ( !openSpeedDial ) setOpenSpeedDial(true);
  }
  const handleCloseSpeedDial = () => {
    setOpenSpeedDial(false);
  }

  useEffect(() => {
    if ( !selectedWorkflowType ) return;

    if ( !open ) toggle();
    // eslint-disable-next-line
  }, [selectedWorkflowType])

  const handleClick = (workflowType:WorkflowType) => () => {
    setSelectedWorkflowType(workflowType);
    handleCloseSpeedDial();
  }

  const handleClose = () => {
    if ( open ) toggle();
    setSelectedWorkflowType(undefined);
  }

  return (
    <Fragment>
      <Backdrop open={openSpeedDial} onClick={handleCloseSpeedDial} />
      <SpeedDial
        ariaLabel="Speed dial create workflow"
        sx={{
          position: 'fixed',
          right: 16,
          bottom: 16
        }}
        FabProps={{
          variant: 'extended'
        }}
        icon={<Fragment>{t('pages.adminPages.automatedWorkflowsPage.speedDial')}</Fragment>}
        open={openSpeedDial}
        onClick={handleOpenSpeedDial}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            sx={{
              // Set styles for speedDialAction tooltips
              '.MuiSpeedDialAction-staticTooltipLabel': {
                minWidth: 120,
                textAlign: 'center'
              },
              '.MuiSpeedDialAction-staticTooltipLabel:hover': {
                cursor: 'pointer'
              }
            }}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={handleClick(action.workflowType)}
          />
        ))}
      </SpeedDial>
      {/* Dialog */}
      {open ? (
        <WorkflowFormDialog
          open={open}
          onClose={handleClose}
          workflowType={selectedWorkflowType}
        />
      ) : null}
    </Fragment>
  )
}

export default AutomatedWorkflowsFab;
