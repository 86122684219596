import config from './config';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './old_index.css';
import './index.css';

import './i18n';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Event, EventHint, init, ErrorBoundary } from '@sentry/react';
// Mui
import { StyledEngineProvider, ThemeProvider, CssBaseline } from '@mui/material';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

// Components
import App from 'app/App';
import Fallback from 'Fallback';

import store, { history } from 'app/store';
import theme from './theme';

const root = document.getElementById('root') as HTMLElement;
const rootElem = createRoot(root);

const appVersion = process.env.REACT_APP_VERSION || 0;
const environment = process.env.REACT_APP_STAGE || 'development';
const isEnvQAorProduction = environment === 'qa' || environment === 'production';

if ( isEnvQAorProduction ){
  init({
    dsn: config.sentryDsn,
    environment,
    beforeSend(event:Event, hint:EventHint) {
      event.extra = {...(event.extra || {})};
      if ( hint && hint.originalException ){
        try {
          const keys = Object.keys(hint.originalException)
          if ( keys.length ){
            event.extra.errorProperties = {};
            keys.forEach(key => {
              (event.extra as any).errorProperties[key] = (hint.originalException as any)[key]
            })
          }
        } catch (error) {
          console.warn('[sentry] Failed to assign enumerable error properties to extras', error)
        }
      }
      event.extra.appVersion = appVersion;
      // if ( hint && hint.originalException instanceof Event ){
      //   event.extra.isTrusted = hint.originalException.isTrusted;
      //   event.extra.detail = hint.originalException.detail;
      //   event.extra.type = hint.originalException.type;
      // }
      return event;
    },
  });
}

rootElem.render(
  <StyledEngineProvider>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <CssBaseline />
          <ErrorBoundary fallback={<Fallback />}>
            <App />
          </ErrorBoundary>
        </ConnectedRouter>
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>
);

if ( config.production && window ) window.console.log = function(){};
