import { Middleware } from '@reduxjs/toolkit';
import { captureMessage } from '@sentry/react';
// Types
import Reducers from 'app/types/Reducers';
// Async
import { chargeInvoice } from 'app/store/BillingCharges/BillingCharges.async';

let sentryMessage:string = '';

const BillingChargesMiddleware:Middleware = () => (next:any) => (action:any) => {
  const { type } = action;

  if ( (type as string).startsWith(Reducers.BillingCharges) ){
    if ( type === chargeInvoice.pending.type ){
      const { meta } = action;
      sentryMessage = meta.arg.card
        ? 'Payment failure during credit card processing'
        : 'Payment failure during ACH processing'
      ;
    } else if ( type === chargeInvoice.rejected.type ){
      const { meta, payload } = action;
      captureMessage(sentryMessage, {
        level: 'error',
        extra: {
          billingInvoiceId: meta.arg.invoiceId,
          heartlandErrorResponse: payload.errors
        }
      });
      sentryMessage = '';
    }
  }

  return next(action);
}

export default BillingChargesMiddleware;
