import { FC, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import { IConversation, IMessage } from 'app/models/ChatAI';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { refineAnswer } from 'app/store/AIChat/AIChat.async';
// Selectors
import { selectConversation, selectRefineParentId } from 'app/store/AIChat/AIChat.selectors';
// Mui
import { Box, Tooltip } from '@mui/material';
// Icons
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  CachedOutlined as CachedOutlinedIcon
} from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
// Context
import { useMessageContext } from './Message.context';

type Props = {
  message: IMessage;
  showRefineButton: boolean;
}

let timeout:any = null;

const MessageActions:FC<Props> = ({
  // Props
  message, showRefineButton
}) => {
  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const conversation = useAppSelector(selectConversation) as IConversation;
  const refineParentId = useAppSelector(selectRefineParentId);

  const { refineMessages, activeIndex, onPrev, onNext } = useMessageContext();

  const boxRef = useRef<HTMLButtonElement | null>(null);

  const [ isClicked, setIsClicked ] = useState(false);
  const [ isBoxInView, setIsBoxInView ] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsBoxInView(entry.isIntersecting);
        });
      },
      { threshold: 1.0 }
    );

    if ( boxRef.current ) observer.observe(boxRef.current);
    return () => {
      // eslint-disable-next-line
      if ( boxRef.current ) observer.unobserve(boxRef.current);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( !isClicked ) return;

    if ( isBoxInView ) return;

    if ( timeout ) clearTimeout(timeout);

    timeout = setTimeout(() => {
      if ( !boxRef.current ) return;
      boxRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });

      setIsClicked(false);
    }, 250);
    // eslint-disable-next-line
  }, [isClicked, isBoxInView]);

  const handleClick = () => {
    dispatch(refineAnswer({
      messageId: message.id as string,
      parentId: message.parentId || '',
      conversationId: conversation.id
    }));
  }

  const handlePrevNext = (next:boolean = false) => () => {
    !next ? onPrev() : onNext();
    setIsClicked(true);
  }

  const refineMessagesLength = refineMessages.length;

  const loadingRefine = refineParentId === message.parentId;

  const isPrevButtonDisabled = activeIndex === 0 || loadingRefine;
  const isNextButtonDisabled = activeIndex === refineMessagesLength - 1 || loadingRefine;

  if ( !showRefineButton && refineMessagesLength <= 1 ) return null;
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 1,
      ml: 'auto'
    }}>
      
      {refineMessagesLength > 1 ? (
        <Box
          ref={boxRef}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <Tooltip title={t('pages.staffPages.caseDetailsPage.prev')}>
            <span>
              <IconButton
                name={`Prev refine ${message.id}`}
                disabled={isPrevButtonDisabled}
                onClick={handlePrevNext()}
                size="small"
              ><ChevronLeftIcon fontSize="small"/></IconButton>
            </span>
          </Tooltip>
          <Tooltip title={t('pages.staffPages.caseDetailsPage.next')}>
            <span>
              <IconButton
                name={`Next refine ${message.id}`}
                disabled={isNextButtonDisabled}
                onClick={handlePrevNext(true)}
                size="small"
              ><ChevronRightIcon fontSize="small" /></IconButton>
            </span>
          </Tooltip>
        </Box>
      ) : null}
      {showRefineButton ? (
        <Tooltip title={t('pages.staffPages.caseDetailsPage.refine')}>
          <IconButton
            name={`Refine answer ${message.parentId}`}
            disabled={loadingRefine}
            onClick={handleClick}
            size="small"
          ><CachedOutlinedIcon fontSize="small" className={loadingRefine ? 'rotate' : ''} /></IconButton>
        </Tooltip>
      ) : null}
    </Box>
  )
}

export default MessageActions;
