import { FC, Fragment } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectTeamsAsOptions } from 'app/store/Teams/Teams.selectors';
import { selectAccountsAsOptions } from 'app/store/Clients/Clients.selectors';
// Mui
import { Box, Divider, Typography, FormHelperText } from '@mui/material';
// Components
import { Autocomplete } from 'app/components/Autocomplete';
import { Input, Switch, DesktopDatePicker, DesktopTimePicker } from 'app/components/Mui';
// Utility
import { isRequired, isDateValid, isTimeValid, isMaxLength } from 'app/utilities/Validations';

interface IFormData {
  name: string;
  reportTemplateId: string | number;
  teamId: string | number;
  clientAccountId?: string | number;
  eventDate: Dayjs | null,
  eventTime: Dayjs | null;
  settings: {
    aiChatEnabled: boolean;
  }
}

const GeneralStepContent:FC = () => {
  const { t } = useTranslation('common');

  // State
  const teamsOptions = useAppSelector(selectTeamsAsOptions);
  const accountsOptions = useAppSelector(selectAccountsAsOptions);

  const { control, formState: { errors }, watch } = useFormContext<IFormData>();

  const watchEventDate= watch('eventDate');

  return (
    <Fragment>
      <Controller
        control={control} name="name"
        rules={{
          required: isRequired,
          maxLength: isMaxLength(100)
        }}
        render={({ field }) => (
          <Input
            {...field}
            label={t('labels.caseName')}
            required
            error={Boolean(errors.name)}
            helperText={errors.name?.message || ''}
            InputProps={{
              endAdornment: (
                <FormHelperText
                  sx={{ p: 0, whiteSpace: 'nowrap' }}
                >{`${watch('name').length} / 100`}</FormHelperText>
              )
            }}
          />
        )}
      />
      <Box sx={{ display: 'flex', gap: 4 }} >
        <Controller
          control={control} name="eventDate"
          rules={{
            required: isRequired,
            validate: isDateValid
          }}
          render={({ field }) => (
            <DesktopDatePicker
              {...field}
              label={t('labels.eventDate')}
              inputProps={{
                required: true,
                error: Boolean(errors.eventDate),
                helperText: errors.eventDate?.message || ''
              }}
            />
          )}
        />
        {watchEventDate ? (
          <Controller
            control={control} name="eventTime"
            rules={{
              validate: {
                pattern: isTimeValid
              }
            }}
            render={({ field }) => (
              <DesktopTimePicker
                {...field}
                label={t('labels.eventTime')}
                inputProps={{
                  error: Boolean(errors.eventTime),
                  helperText: errors.eventTime?.message || ''
                }}
              />
            )}
          />
        ) : null}
      </Box>
      <Divider sx={{ mt: 4, mb: 2 }} />
      <Controller
        control={control} name="teamId"
        render={({ field }) => (
          <Autocomplete
            {...field}
            label={t('labels.team')}
            options={teamsOptions || []}
            renderOptions={(props, option) => (
              <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.id}>
                <Typography variant="subtitle1">{option.name}</Typography>
                {option.subname ? (
                  <Typography variant="caption">{option.subname}</Typography>
                ) : null}
              </li>
            )}
          />
        )}
      />
      {/* Client account */}
      <Controller
        control={control} name="clientAccountId"
        render={({ field }) => (
          <Autocomplete
            {...field}
            label={t('labels.clientAccount')}
            options={accountsOptions || []}
            renderOptions={(props, option) => (
              <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.id}>
                <Typography variant="subtitle1">{option.name}</Typography>
                {option.props?.fullAddress ? (
                  <Typography variant="caption">{option.props.fullAddress}</Typography>
                ) : null}
              </li>
            )}
            disableClearable={true}
          />
        )}
      />
      {/* Enable AI Chat */}
      <Controller
        control={control} name="settings.aiChatEnabled"
        render={({ field:{ onChange, ...otherField } }) => (
          <Switch
            {...otherField}
            label="Enable AI Chat"
            onChange={(_, checked:boolean) => onChange(checked)}
          />
        )}
      />
    </Fragment>
  );
};

export default GeneralStepContent;