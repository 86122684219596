import { FC, Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Models
import { IMyUser } from 'app/models/User';
import { ICurrentAccount } from 'app/models/Account';
import IInsuranceCase from 'app/models/Case';
import { IPrompt } from 'app/store/AIChatPrompts/AIChatPrompts.models';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createConversation, createQuestion } from 'app/store/AIChat/AIChat.async';
// Actions
import { AIChatActions } from 'app/store/AIChat/AIChat.slice';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectCurrentAccount } from 'app/store/Accounts/Accounts.selectors';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectConversation, selectLoadingMessage, selectStatusMessage } from 'app/store/AIChat/AIChat.selectors';
import { selectHasAnyDocumentOcred } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Mui
import { Box, Tooltip } from '@mui/material';
// Icons
import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';
// Components
import { IconButton, Input, LoadingButton } from 'app/components/Mui';
// Dialogs
import AIPromptSelectDialog from 'app/dialogs/AIPromptSelectDialog';
// Hooks
import useToggle from 'app/hooks/useToggle';
// ToDO: Should be moved from components
import MixpanelTracks from "app/types/MixpanelTracks";
import Mixpanel from "app/services/Mixpanel.service";

interface IFormData {
  value: string;
  rows: number;
}

const ConversationMessageForm:FC = () => {
  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const myUser = useAppSelector(selectMyUser) as IMyUser;
  const currentAccount = useAppSelector(selectCurrentAccount) as ICurrentAccount;
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;
  const conversation = useAppSelector(selectConversation);
  const loadingMessage = useAppSelector(selectLoadingMessage);
  const statusMessage = useAppSelector(selectStatusMessage);
  const hasAnyDocumentOcred = useAppSelector(selectHasAnyDocumentOcred);

  const { open, toggle } = useToggle();

  const [ loading, setLoading ] = useState(false);

  const { register, reset, setValue, watch } = useForm<IFormData>({
    defaultValues: {
      value: '',
      rows: 1,
    },
  });

  const watchValue = watch('value');
  const watchRows = watch('rows');

  const resetForm = () => {
    if ( loading ) setLoading(false);
    reset({ value: '', rows: 1 });
    dispatch(AIChatActions.setStatusMessage('initial'));
  }

  useEffect(() => {
    if ( statusMessage === 'sent' ) resetForm();
    // eslint-disable-next-line
  }, [statusMessage]);

  useEffect(() => {
    if ( !conversation && loading ) resetForm();
    // eslint-disable-next-line
  }, [conversation, loading]);

  const appendValueWithRows = (value:string) => {
    const lineCount = (value.match(/\n/g) || []).length + 1;
    setValue('value', value);
    setValue('rows', Math.min(lineCount, 4));
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // Hz how it works, but when I remove this line, "Shift+Enter" stop works
    if ( event.key === 'Enter' && event.shiftKey ){
      // event.preventDefault(); // Prevent default behavior (new line)
    } else if ( event.key === 'Enter' ){
      event.preventDefault(); // Prevent default behavior (form submission)
      handleClick();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    appendValueWithRows(value);
  };

  const handleClick = async () => {
    if ( !watchValue || !watchValue.trim() ) return;

    setLoading(true);

    try {
      if ( conversation ){
        const conversationId = conversation.id;
        await dispatch(createQuestion({
          conversationId,
          data: { question: watchValue }
        }));
      } else {
        await dispatch(createConversation({
          caseId: insuranceCase.id,
          question: watchValue
        }));
      }
    } catch(error){
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const handleConfirm = async (aiPrompt:IPrompt) => {
    const mixpanelData:any = {
      accountId: currentAccount.id,
      userId: myUser.id,
      caseId: insuranceCase.id,
      promptId: aiPrompt.id,
    };

    if ( conversation ){
      mixpanelData['conversationId'] = conversation.id;
      dispatch(createQuestion({
        conversationId: conversation.id,
        data: { question: aiPrompt.content }
      }));
    } else {
      dispatch(createConversation({
        caseId: insuranceCase.id,
        promptId: aiPrompt.id,
        // Read `promptQuestion` in `AIChat.middleware` on `createConversation`
        // to generate `humanMessage`
        // we exclude `promptQuestion` from `data` sent on `createConversation`
        // Better way, will be another action which will set `question`
        promptQuestion: aiPrompt.content
      }));
    }

    Mixpanel.track(MixpanelTracks.AIChatConversationPromptUsed, mixpanelData);
  }

  const hasProcessedDocumentsAndCaseReady = hasAnyDocumentOcred && insuranceCase?.processing?.status === 'ready';
  const enableControls = Boolean(hasProcessedDocumentsAndCaseReady && !loadingMessage);

  return (
    <Fragment>
      <Box sx={{
        width: '100%',
        maxWidth: 920,
        mx: 'auto',
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        py: 4,
        px: 8
      }}>
        <input {...register('rows')} type="hidden" value={watchRows} />
        <Tooltip title="Prompt Hub">
          {/* Tooltip doesn`t work with disabled button, span added */}
          <span>
            <IconButton
              name="Prompt Hub"
              onClick={toggle}
              disabled={!enableControls}
            ><AddCircleOutlineIcon /></IconButton>
          </span>
        </Tooltip>
        <Input
          {...register('value')}
          label={t('labels.message')}
          value={watchValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          multiline
          rows={watchRows}
          margin="none"
          size="small"
          disabled={!enableControls}
        />
        <LoadingButton
          name="Send message"
          loading={loading}
          disabled={!enableControls}
          onClick={handleClick}
          variant="contained"
          color="primary"
        >{t('labels.send')}</LoadingButton>
      </Box>
      {open ? (
        <AIPromptSelectDialog
          open={open}
          onClose={toggle}
          onConfirm={handleConfirm}
        />
      ) : null}
    </Fragment>
  )
}

export default ConversationMessageForm;
