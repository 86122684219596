// Models
import IInsuranceCase from "app/models/Case";
// Store
import { useAppSelector } from "./useStore";
// Selectors
import { selectCaseAiChatEnabled, selectInsuranceCase } from "app/store/Cases/Cases.selectors";

const useCasePermissionResolved = () => {
  // State
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;
  const caseAIChatEnabled = useAppSelector(selectCaseAiChatEnabled);

  const isCaseProcessingMissing = !insuranceCase.processing;
  const isCaseProcessingReadyOrNotStarted = isCaseProcessingMissing || insuranceCase.processing.status === 'ready';
  const isCaseProcessingStatusNotReady = insuranceCase.processing && insuranceCase.processing.status !== 'ready';

  // Possible conditions:
  // caseAIChatEnabled && isCaseProcessingStatusNotReady - readOnlyMode ( createNewConversation, sendNewMessage, refine )

  // caseAIChatEnabled && isCaseProcessingMissing - Text "Upload documents to be able to create new conversations." + Upload button.
  // caseAIChatEnabled && isCaseProcessingStatusNotReady - Text "Wait for case to be in status Ready to create new conversations. You can still access existing conversations."
  // !caseAIChatEnabled && isCaseProcessingReadyOrNotStarted - Hints + "Enable AI Chat button"
  // !caseAIChatEnabled && isCaseProcessingStatusNotReady - Text: Keep existing text. Above Enable button add bold text  "Wait for case to be in status Ready to enable AI Chat" + disabled "Enable AI Chat" button below

  return {
    caseAIChatEnabled,
    isCaseProcessingMissing,
    isCaseProcessingReadyOrNotStarted,
    isCaseProcessingStatusNotReady
  };
}

export default useCasePermissionResolved;
