import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
import { WorkflowType } from './AutomatedWorkflows.types';
// Models
import { RootState } from 'app/store';
import { IWorkflow } from './AutomatedWorkflows.models';
// Utilities
import { sortByName } from 'app/utilities/SortBy';

export const selectWorkflows = (state:RootState) => state[Reducers.AutomatedWorkflows].workflows;
export const selectLoading = (state:RootState) => state[Reducers.AutomatedWorkflows].loading;

export const selectSortedWorkflows = createSelector(
  [ selectWorkflows ],
  ( workflows:IWorkflow[] | null ) => {
    if ( !workflows ) return null;
    return [...workflows].sort((a, b) => sortByName(a, b, 'name'));
  }
)

export const selectInstantWorkflows = createSelector(
  [ selectSortedWorkflows ],
  ( workflows:IWorkflow[] | null ) => {
    if ( !workflows ) return null;
    return workflows
      .filter((workflow:IWorkflow) => workflow.enabled)
      .filter((workflow:IWorkflow) => workflow.type === WorkflowType.Instant)
  }
);
