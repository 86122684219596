import { FC, useState, useEffect } from 'react';
// Models
import IInsuranceCase from 'app/models/Case';
import { IWorkflow } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.models';
// store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getWorkflows } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.async';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectInstantWorkflows } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.selectors';
import { executeInstantWorkflow } from 'app/store/AutomatedWorkflowsExecutions/AutomatedWorkflowsExecutions.async';
// Mui
import { Box, Toolbar, Paper, Typography, Tooltip, CircularProgress } from '@mui/material';
// Icons
import {
  PlayCircleFilledWhiteOutlined as PlayCircleFilledWhiteOutlinedIcon
} from '@mui/icons-material';
// Components
import DataLoading from 'app/components/DataLoading';
import { IconButton } from 'app/components/Mui';
// Constants
import { SIDEBAR_WIDTH } from 'app/App.constants';

const WorkflowsSidebar:FC = () => {
  // Dispatch
  const dispatch = useAppDispatch();
  // Selectors
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;
  const workflows = useAppSelector(selectInstantWorkflows);

  const [ disabledWorkflowIds, setDisabledWorkflowIds ] = useState<number[]>([]);

  useEffect(() => {
    dispatch(getWorkflows({}));
    // eslint-disable-next-line
  }, []);

  const handleClick = (workflowId:number) => async () => {
    setDisabledWorkflowIds(prevState => [...prevState, workflowId]);
    try {
      await dispatch(executeInstantWorkflow({
        caseId: insuranceCase.id,
        workflowId
      }));
    } catch(error){
      console.error(error)
    } finally {
      setDisabledWorkflowIds(prevState => prevState.filter(id => id !== workflowId));
    }
  }

  const renderWorkflowItem = (workflow:IWorkflow) => {
    const disabledWorkflow = disabledWorkflowIds.includes(workflow.id);
    return (
      <Paper
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 4
        }}
        variant="outlined"
      >
        <Box sx={{ flexGrow: 1, pr: 2 }}>
          <Typography variant="h6">{workflow.name}</Typography>
        </Box>
        <Tooltip title="Run">
          <span>
            <IconButton
              name={`Run workflow ${workflow.id}`}
              aria-label="Run workflow button"
              onClick={handleClick(workflow.id)}
              disabled={disabledWorkflow}
            >{disabledWorkflow ? <CircularProgress size={24} thickness={6} /> : <PlayCircleFilledWhiteOutlinedIcon />}</IconButton>
          </span>
        </Tooltip>
      </Paper>
    );
  }

  return (
    <Box sx={{
      flexShrink: 0,
      display: 'flex',
      flexDirection: 'column',
      width: SIDEBAR_WIDTH,
      bgcolor: 'white',
      borderRight: '1px solid rgba(0,0,0,0.08)',
      overflowY: 'auto'
    }}>
      <Toolbar>
        <Typography variant="h6">Workflows</Typography>
      </Toolbar>
      <DataLoading
        data={workflows}
        text="No workflow(s)"
      >
        {(data) => (
          <Box sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            overflowY: 'auto',
            p: 4
          }}>
            {data.map((workflow) => (
              renderWorkflowItem(workflow)
            ))}
          </Box>
        )}
      </DataLoading>
    </Box>
  )
}

export default WorkflowsSidebar