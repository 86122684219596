import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';

export const selectExportTemplates = (state:RootState) => state[Reducers.ExportTemplates].exportTemplates;
export const selectExportTemplate = (state:RootState) => state[Reducers.ExportTemplates].exportTemplate;
export const selectLoading = (state:RootState) => state[Reducers.ExportTemplates].loading;

export const selectExportTemplatesAsOptions = createSelector(
  [ selectExportTemplates ],
  (exportTemplates:any[] | null) => {
    if ( !exportTemplates ) return null;
    return [
      { id: '', name: 'Choose report template' },
      ...exportTemplates.map((exportTemplate:any) => ({
        id: exportTemplate.id,
        name: exportTemplate.name
      }))
    ];
  }
)