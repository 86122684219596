import { FC, useRef, useEffect, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Models
import { IMessage } from 'app/models/ChatAI';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getConversation } from 'app/store/AIChat/AIChat.async';
// Actions
import { AIChatActions } from 'app/store/AIChat/AIChat.slice';
// Selectors
import { selectDisableGetConversation, selectConversationMessagesArray, selectLoadingMessage } from 'app/store/AIChat/AIChat.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import DataLoading from 'app/components/DataLoading';
// 
import MessageItem from './MessageItem';
import MessageSkeleton from './MessageSkeleton';

const ConversationMessageList:FC = () => {
  const { t } = useTranslation('common');

  const { conversationId } = useParams<{ conversationId:string }>();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const messagesArray = useAppSelector(selectConversationMessagesArray);
  const loadingMessage = useAppSelector(selectLoadingMessage);
  const disableGetConversation = useAppSelector(selectDisableGetConversation);

  const messagesEndRef = useRef<any>(null);

  const scrollToBottom = () => messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });

  useEffect(() => {
    if ( disableGetConversation ){
      dispatch(AIChatActions.setDisableGetConversation(false));
    } else {
      dispatch(getConversation(conversationId));
    }
    return () => {
      dispatch(AIChatActions.setInitialField('conversation'));
      dispatch(AIChatActions.setInitialField('refineParentId'));
      dispatch(AIChatActions.setInitialField('loadingMessage'));
      dispatch(AIChatActions.setInitialField('statusMessage'));
    }
    // eslint-disable-next-line
  }, [conversationId]);

  useLayoutEffect(() => {
    scrollToBottom();
    // eslint-disable-next-line
  }, [messagesArray]);

  return (
    <DataLoading
      data={messagesArray}
      text={t('pages.staffPages.caseDetailsPage.noMessages')}
    >
      {(data) => (
        <Box sx={{
          width: '100%',
          maxWidth: 920,
          mx: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 4,
          py: 4,
          px: 8
        }}>
          {data.map((messages:IMessage[], index:number) => (
            <MessageItem
              key={`message item ${index}`}
              messages={messages}
            />
          ))}
          {loadingMessage ? <MessageSkeleton relative={true} /> : null}
          <div ref={messagesEndRef} />
        </Box>
      )}
    </DataLoading>
  )
}

export default ConversationMessageList;
