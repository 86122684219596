import { FC, Fragment, useMemo, useState } from 'react';
// Models
import { IMessage, IMessagePart } from 'app/models/ChatAI';
// Mui
import { Theme, Box, Typography, Tooltip } from '@mui/material';
// Icons
import { BookmarkAddOutlined as BookmarkAddOutlinedIcon } from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
// Dialogs
import AIPromptFormDialog from 'app/dialogs/AIPromptFormDialog';
// Hooks
import useToggle from 'app/hooks/useToggle';
// Utilities
import { parseDateFromUtcToLocal } from '_utilities/datetime.utility';
// ToDO
import CardPageBodyPreview from 'app/components/CardPage/CardPageBodyPreview';
// End: ToDO

type IRef = IMessagePart['references'][0];
type IMetadata = IRef['metadata'];

type Props = {
  message: IMessage;
  percents?: number;
}

const MessageText:FC<Props> = ({
  // Props
  message, percents
}) => {
  const isHumanMessage = message.type === 'human';

  const { open, toggle } = useToggle();

  const [ metadata, setMetadata ] = useState<any>(null);

  const handleClick = (metadata:IMetadata | null) => () => {
    setMetadata(metadata);
  }

  const renderSup = (index:number, metadata:IMetadata) => (
    <Box
      key={`ref item ${index}`}
      sx={{ mx: '1px', color: 'yellow', '&:hover': { cursor: 'pointer' }}}
      onClick={handleClick(metadata)}
      component="sup"
    >[{index}]</Box>
  );

  const text = useMemo(() => {
    if ( isHumanMessage || (!message.parts || !message.parts.length) ) return message.text || '';
    let i = 0;
    return message.parts.map((part:IMessagePart, index) => {
      if ( part.references && part.references.length ){
        return (
          <Fragment key={`text item with ref ${i + index}`}>
            {part.text}
            {part.references.map((ref:IRef) => {
              i++;
              return renderSup(i, ref.metadata);
            })}
          </Fragment>
        );
      } else {
        return <Fragment key={`text item ${index}`}>{part.text}</Fragment>;
      }
    });
    // eslint-disable-next-line
  }, [message, isHumanMessage]);

  return (
    <Fragment>
      <Box
        sx={{
          position: 'relative',
          minWidth: 240,
          bgcolor: (theme:Theme) => isHumanMessage
            ? 'white'
            : message.error ? theme.palette.grey[600] : theme.palette.primary.main
          ,
          border: (theme:Theme) => `1px solid ${
            isHumanMessage
              ? message.error ? theme.palette.error.main : 'rgba(0,0,0,0.12)'
              : 'transparent'
            
          }`,
          borderRadius: !isHumanMessage && percents ? `8px 8px 0 0` : 2,
          color: (theme:Theme) => isHumanMessage
            ? message.error ? theme.palette.error.main : 'black'
            : 'white'
          ,
          pt: 4,
          px: 4,
          pb: message.createdOn ? 7 : 4,
          whiteSpace: 'pre-line',
          lineHeight: 1.6
        }}
      >
        {text}
        {message.createdOn ? (
          <Typography sx={{
            position: 'absolute',
            bottom: 0, right: 0,
            fontSize: 11,
            mr: 4,
            mb: 1
          }} variant="caption">{parseDateFromUtcToLocal(message.createdOn)}</Typography>
        ) : null}
        {isHumanMessage ? (
          <Box
            className="createPromptButton"
            sx={{
              position: 'absolute',
              right: 0, top: '50%',
              mt: message.createdOn ? -1.5 : 0,
              mr: -10,
              transform: 'translateY(-50%)'
            }}
          >
            <Tooltip title="Create prompt">
              <IconButton
                name="Create prompt"
                onClick={toggle}
                size="small"
              ><BookmarkAddOutlinedIcon fontSize="small" /></IconButton>
            </Tooltip>
          </Box>
        ) : null}
      </Box>
      {metadata && metadata.documentId && metadata.page ? (
        <CardPageBodyPreview
          documentId={metadata.documentId}
          pageNum={metadata.page}
          isLeft={false}
          onClose={handleClick(null)}
          backdrop={
            <Box
              sx={{
                position: 'fixed',
                top: 0, left: 0,
                width: '100%',
                height: '100%',
                zIndex: 1
              }}
              onClick={handleClick(null)}
            />
          }
        />
      ) : null}
      {open ? (
        <AIPromptFormDialog
          open={open}
          onClose={toggle}
          content={message.text}
        />
      ) : null}
    </Fragment>
  )
}

export default MessageText;
