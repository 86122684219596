import { Switch, Route, Redirect } from 'react-router-dom';
// Pages
// Admin
import UsersPage from 'app/pages/Admin/UsersPage';
import TeamsPage from 'app/pages/Admin/TeamsPage';
import CompanyAccountPage from 'app/pages/Admin/CompanyAccountPage';
import ExportTemplatesPage from 'app/pages/Admin/ExportTemplatesPage';
import BillingInvoicesPage from 'app/pages/Admin/BillingInvoicesPage';
import TemplatesPage from 'app/pages/Admin/TemplatesPage';
import PresetsPage from 'app/pages/Admin/PresetsPage';
import AuthorRegistryPage from 'app/pages/Admin/AuthorRegistryPage';
import AnalyticsPage from 'app/pages/Admin/AnalyticsPage';
import ReportSectionTemplatesPage from 'app/pages/Admin/ReportSectionTemplatesPage';
import CollectionsPage from 'app/pages/Admin/CollectionsPage';
import TermsPage from 'app/pages/Admin/TermsAndConditionsPage';
import AIPromptHubPage from 'app/pages/Admin/AIPromptHubPage';
// Staff
import CasesPage from 'app/pages/Staff/CasesPage';
import ClientAccountsPage from 'app/pages/Staff/ClientAccountsPage';
// Components
import Header from 'app/components/Header';
// ToDO: V3
import CaseDetailsPage from 'pages/Admin/CaseDetailsPage';
import AutomatedWorkflowsPage from 'app/pages/Admin/AutomatedWorkflowsPage';

const Admin = ({
  // Props
  match
}) => {
  const { path } = match;

  const { pathname, search } = window.location;

  const redirectTo = `${pathname.includes('litigation-cases')
    ? pathname.replace('litigation-cases', 'cases')
    : `${path}/cases`
  }${search}`;

  return (
    <>
      <Header />
      <Switch>
        <Route exact path={`${path}/cases`} component={CasesPage} />
        <Route path={`${path}/cases/:caseId`} component={CaseDetailsPage} />

        <Route path={`${path}/billing-invoices`} component={BillingInvoicesPage} />
        <Route path={`${path}/author-registry`} component={AuthorRegistryPage} />

        <Route path={`${path}/users`} component={UsersPage} />
        <Route path={`${path}/teams`} component={TeamsPage} />
        <Route path={`${path}/client-accounts`} component={ClientAccountsPage} />
        <Route path={`${path}/company-account`} component={CompanyAccountPage} />
        <Route path={`${path}/report-templates`} component={ExportTemplatesPage} />
        <Route path={`${path}/report-section-templates`} component={ReportSectionTemplatesPage} />

        <Route path={`${path}/templates`} component={TemplatesPage} />
        <Route path={`${path}/presets`} component={PresetsPage} />

        <Route path={`${path}/analytics`} component={AnalyticsPage} />
        <Route path={`${path}/terms-and-conditions`} component={TermsPage} />

        <Route path={`${path}/ai-prompt-hub`} component={AIPromptHubPage} />

        <Route path={`${path}/document-collections`} component={CollectionsPage} />

        <Route path={`${path}/automated-workflows`} component={AutomatedWorkflowsPage} />
        <Redirect from={path} to={redirectTo} />
      </Switch>
    </>
  );
}

export default Admin;
