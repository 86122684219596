import { FC, useEffect } from 'react';
// Models
import IInsuranceCase from 'app/models/Case';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getExecutions } from 'app/store/AutomatedWorkflowsExecutions/AutomatedWorkflowsExecutions.async';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectSortedExecutions } from 'app/store/AutomatedWorkflowsExecutions/AutomatedWorkflowsExecutions.selectors';
// Mui
import { Box, Container } from '@mui/material';
// Components
import DataLoading from 'app/components/DataLoading';
import WorkflowExecutionCard from 'app/components/WorkflowExecutionCard';
// 
import WorkflowsSidebar from './WorkflowsSidebar';

const WorkflowsPage:FC = () => {
  // Dispatch
  const dispatch = useAppDispatch();
  // Selectors
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;
  const executions = useAppSelector(selectSortedExecutions);

  useEffect(() => {
    dispatch(getExecutions({ caseId: insuranceCase.id }));
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'auto' }}>
      <WorkflowsSidebar />
      <Box sx={{ flexGrow: 1, overflowY: 'auto', position: 'relative' }} react-action="scroll">
        <DataLoading
          data={executions}
          text="No execution(s)"
        >
          {(data) => (
            <Container sx={{ py: 4 }} maxWidth="md">
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                {data.map((execution) => (
                  <WorkflowExecutionCard
                    key={`workflow execution item ${execution.id}`}
                    execution={execution}
                  />
                ))}
              </Box>
            </Container>
          )}
        </DataLoading>
      </Box>
    </Box>
  )
}

export default WorkflowsPage