import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import { IState, IWorkflow } from "./AutomatedWorkflows.models";
// Async
import { getWorkflows, createWorkflow, updateWorkflow, deleteWorkflow } from './AutomatedWorkflows.async';

const initialState:IState = {
  workflows: null,
  loading: false
};

const slice = createSlice({
  name: Reducers.AutomatedWorkflows,
  initialState,
  reducers: {
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  },
  extraReducers: (builder) => {
    // Get workflows
    builder.addCase(getWorkflows.pending, (state) => {
      state.workflows = null;
    });
    builder.addCase(getWorkflows.fulfilled, (state, action:PayloadAction<IWorkflow[]>) => {
      state.workflows = action.payload;
    });
    // Create workflow
    builder.addCase(createWorkflow.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createWorkflow.fulfilled, (state, action:PayloadAction<IWorkflow>) => {
      if ( state.workflows ){
        state.workflows.push(action.payload);
      }
    });
    // Update workflow
    builder.addCase(updateWorkflow.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateWorkflow.fulfilled, (state, action:PayloadAction<IWorkflow>) => {
      if ( state.workflows ){
        state.workflows = state.workflows.map((workflow:IWorkflow) => {
          if ( workflow.id === action.payload.id ) return action.payload;
          return workflow;
        })
      }
    });
    // Delete workflow
    builder.addCase(deleteWorkflow.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteWorkflow.fulfilled, (state, action:{ payload:number }) => {
      if ( state.workflows ){
        state.workflows = state.workflows.filter((workflow:IWorkflow) => workflow.id !== action.payload);
      }
    });
    // Match
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  }
});

export const AutomatedWorkflowsActions = slice.actions;

export default slice.reducer;