import { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
// Types
import MixpanelTracks from "app/types/MixpanelTracks";
// Models
import { AppDispatch } from 'app/store';
// Async
import { createAssignment, deleteAssignment } from 'app/store/CaseAssignments/CaseAssignments.async';
// Services
import Mixpanel from "app/services/Mixpanel.service";

let caseId:number | undefined;

const CaseAssignmentsMiddleware:Middleware = ({ getState }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  const { type } = action;

  if ( type === createAssignment.pending.type ){
    caseId = action.meta.arg.insuranceCaseId
  } else if ( type === createAssignment.fulfilled.type ){
    if ( caseId ){
      Mixpanel.track(MixpanelTracks.CaseShareAccessAdded, { caseId });
    }
    caseId = undefined;
  }
  if ( type === deleteAssignment.pending.type ){
    caseId = action.meta.arg.insuranceCaseId
  } else if ( type === deleteAssignment.fulfilled.type ){
    if ( caseId ){
      Mixpanel.track(MixpanelTracks.CaseShareAccessRevoked, { caseId });
    }
    caseId = undefined;
  }

  return next(action);
}

export default CaseAssignmentsMiddleware;
