export enum WorkflowTriggers {
  DocumentCreated = 'documentCreated',
  DocumentBatchReady = 'documentBatchReady'
};

export enum WorkflowType {
  Automated = 'automated',
  Instant = 'instant'
}

export enum StepTypes {
  Task = 'task'
};

export enum StepTasks {
  RunPrompt = 'runPrompt',
  CreateDocument = 'createDocument',
  CreateReport = 'createReport',
  SendEmail = 'sendEmail'
};

export const StepTaskLabels:Record<StepTasks, string> = {
  [StepTasks.RunPrompt]: 'Run prompt',
  [StepTasks.CreateDocument]: 'Create document',
  [StepTasks.CreateReport]: 'Create report',
  [StepTasks.SendEmail]: 'Send e-mail',
};
