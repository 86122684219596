import { FC, Fragment, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
// Models
import { ICurrentAccount } from 'app/models/Account';
import { IMyUser } from 'app/models/User';
import IInsuranceCase from 'app/models/Case';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getExportTemplates } from 'app/store/ExportTemplates/ExportTemplates.async';
import { getTeams } from 'app/store/Teams/Teams.async';
import { createInsuranceCase, acceptInsuranceCase, getInsuranceCase } from 'app/store/Cases/Cases.async';
// Api query
import casesApi, { useLazyGetSearchCasesQuery } from 'app/store/Cases/Cases.api';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectCurrentAccount, selectHcpcsCodesEnabled, selectLegacyReportTemplatesEnabled } from 'app/store/Accounts/Accounts.selectors';
import { selectInsuranceCase, selectLoading } from 'app/store/Cases/Cases.selectors';
// Actions
import { CasesActions } from 'app/store/Cases/Cases.slice';
// Mui
import { Box, Paper, Tabs, Tab, Tooltip } from '@mui/material';
// Icons
import { Error as ErrorIcon } from '@mui/icons-material';
// Components
import Dialog from 'app/components/Dialog';
import { Button, LoadingButton } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
// Utilities
import { convertDateToString, convertTimeToString, parseDate, parseTime } from 'app/utilities/DateTime';
// 
import CaseCheckDialog from '../CaseCheckDialog';
import CaseTabContent from './CaseTabContent';
import ClaimantTabContent from './ClaimantTabContent';

interface IFormData {
  name: string;
  reportTemplateId: string | number;
  teamId: string | number;
  clientAccountId?: string | number;
  claimant: {
    name: string;
    dateOfBirth: Dayjs | null;
  },
  settings: {
    medicareOverchargeThreshold: number;
  };
  eventDate: Dayjs | null,
  eventTime: Dayjs | null;
  claimNumber: string;
  notificationPreferences: {
    emails: string[];
  }
};

type Props = {
  open: boolean;
  onClose: () => void;
  caseId?: number;
  // insuranceCase?: IInsuranceCase;
}

const CaseFormDialog:FC<Props> = ({
  // Props
  open, onClose, caseId
}) => {
  const { t } = useTranslation('common');

  const label = caseId ? t('labels.approve') : t('labels.create');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const myUser = useAppSelector(selectMyUser) as IMyUser;
  const currentAccount:ICurrentAccount | null = useAppSelector(selectCurrentAccount);
  const hcpcsCodesEnabled = useAppSelector(selectHcpcsCodesEnabled);
  const legacyReportTemplatesEnabled = useAppSelector(selectLegacyReportTemplatesEnabled);
  const insuranceCase:IInsuranceCase | null = useAppSelector(selectInsuranceCase);
  const loading = useAppSelector(selectLoading);
  // Query
  const [ getSearchCases, { data:searchCasesData, isLoading } ] = useLazyGetSearchCasesQuery();

  const { open:openCheckDialog, toggle:toggleCheckDialog } = useToggle();

  const [ activeTab, setActiveTab ] = useState(0);
  const [ caseFormData, setCaseFormData ] = useState<IFormData | null>(null);
  const [ caseTabError, setCaseTabError ] = useState(false);
  const [ claimantTabError, setClaimantTabError ] = useState(false);

  const methods = useForm<IFormData>({
    defaultValues: {
      name: '',
      reportTemplateId: '',
      teamId: '',
      clientAccountId: '',
      settings: {
        medicareOverchargeThreshold: currentAccount?.settings?.medicareOverchargeThreshold
      },
      claimant: {
        name: '',
        dateOfBirth: null
      },
      eventDate: null,
      eventTime: null,
      claimNumber: '',
      notificationPreferences: {
        emails: [myUser.email]
      }
    }
  });

  const { handleSubmit, getValues, reset } = methods;

  const onSubmit = handleSubmit((data:IFormData) => {
    const { reportTemplateId, teamId, clientAccountId, settings, name, claimant, eventDate, eventTime, claimNumber, notificationPreferences } = data;
    const nextData:any = {
      name,
      settings: {
        aiChatEnabled: insuranceCase?.settings?.aiChatEnabled
      }
    };

    if ( teamId ) nextData['teamId'] = Number(teamId);

    if ( legacyReportTemplatesEnabled && reportTemplateId ) nextData['reportTemplateId'] = Number(reportTemplateId);
    if ( notificationPreferences ){
      const { emails } = notificationPreferences;
      if ( emails && emails.length ){
        nextData['notificationPreferences'] = { emails };
      }
    }

    if ( hcpcsCodesEnabled ) nextData['settings']['medicareOverchargeThreshold'] = Number(settings.medicareOverchargeThreshold);

    if ( !caseId ){
      if ( clientAccountId ) nextData['clientAccountId'] = Number(clientAccountId);
      nextData['claimant'] = { name: claimant.name };
      if ( claimant.dateOfBirth ) nextData['claimant']['dateOfBirth'] = convertDateToString(claimant.dateOfBirth);
      if ( eventDate ){
        nextData['eventDate'] = convertDateToString(eventDate);
        if ( eventTime ) nextData['eventTime'] = convertTimeToString(eventTime);
      }
      if ( claimNumber ){
        nextData['claimNumber'] = claimNumber;
      }
    }

    let claimantName:string = insuranceCase ? insuranceCase.claimant.name : nextData.claimant.name;
    let claimantDateOfBirth:string = insuranceCase ? insuranceCase.claimant.dateOfBirth : nextData.claimant.dateOfBirth;
    
    if ( claimantDateOfBirth ){
      setCaseFormData(nextData);
      getSearchCases({
        params: {
          'claimant.name': claimantName,
          'claimant.dateOfBirth': claimantDateOfBirth,
        },
        caseId
      });
    } else {
      asyncCreateInsuranceCase(nextData);
    }
  });

  const asyncCreateInsuranceCase = async (data?:any) => {
    dispatch(casesApi.util.resetApiState());

    try {
      if ( caseId ){
        await dispatch(acceptInsuranceCase({
          insuranceCaseId: caseId,
          data: data || caseFormData
        })).unwrap();
      } else {
        await dispatch(createInsuranceCase(data || caseFormData)).unwrap();
      }
      onClose();
    } catch(error){}
  }

  useEffect(() => {
    dispatch(getExportTemplates({}));
    dispatch(getTeams({}));

    if ( caseId ) dispatch(getInsuranceCase(caseId));

    return () => {
      dispatch(casesApi.util.resetApiState());
      if ( caseId ){
        dispatch(CasesActions.setInitialField('insuranceCase'));
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( !insuranceCase ) return;

    const initialFormData = getValues();

    const { name, team, eventDate, eventTime } = insuranceCase;

    const caseFormData:any = {
      name,
      eventDate: eventDate ? parseDate(eventDate) : null,
      eventTime: eventTime ? parseTime(eventTime) : null
    };

    if ( team && team.id ) caseFormData['teamId'] = team.id;

    reset({
      ...initialFormData,
      ...caseFormData
    });
    // eslint-disable-next-line
  }, [insuranceCase]);

  useEffect(() => {
    if ( searchCasesData ){
      if ( Object.keys(searchCasesData).length ){
        toggleCheckDialog();
      } else {
        asyncCreateInsuranceCase();
      }
    }
    // eslint-disable-next-line
  }, [searchCasesData]);

  const handleChange = (_:any, nextTab:number) => setActiveTab(nextTab);

  const handleClose = (isAccepted:boolean) => {
    toggleCheckDialog();
    dispatch(casesApi.util.resetApiState());

    if ( !isAccepted ) return;

    asyncCreateInsuranceCase();
  }

  const errorElem = (
    <Tooltip title={t('validations.hasRequiredFields')}><ErrorIcon color="error" /></Tooltip>
  );

  const actions = (
    <Fragment>
      <Button
        name="Cancel case form dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name={`${label} case form dialog`}
        loading={isLoading || loading}
        type="submit"
        variant="contained"
        color="primary"
      >{label}</LoadingButton>
    </Fragment>
  );

  return (
    <Fragment>
      <FormProvider {...methods}>
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth="sm"
          title={`${label} ${t('labels.case')}`}
          actions={actions}

          PaperProps={{
            component: 'form',
            onSubmit,
            noValidate: true
          }}
        >
          {!caseId ? (
            <Paper variant="outlined">
              <Tabs
                value={activeTab}
                onChange={handleChange}
                centered
              >
                <Tab label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {'Case'}
                    {caseTabError ? errorElem : null}
                  </Box>
                }/>
                <Tab label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  {'Claimant'}
                  {claimantTabError ? errorElem : null}
                </Box>
                } />
              </Tabs>
            </Paper>
          ) : null}
          <Box sx={{ display: activeTab === 0 ? 'block' : 'none' }}>
            <CaseTabContent caseId={caseId} onSetError={setCaseTabError} />
          </Box>
          {!caseId ? (
            <Box sx={{ display: activeTab === 1 ? 'block' : 'none' }}>
              <ClaimantTabContent onSetError={setClaimantTabError} />
            </Box>
          ) : null}
        </Dialog>
      </FormProvider>
      {openCheckDialog ? (
        <CaseCheckDialog
          open={open}
          onClose={handleClose}
          searchCasesData={searchCasesData}
        />
      ) : null}
    </Fragment>
  )
}

export default CaseFormDialog;