import config from "../../../config";

// Types
import Reducers from "app/types/Reducers";
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
import { AIChatActions } from "./AIChat.slice";
// Messages
import Messages from "./AIChat.messages";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $delete, $get, $post, $put } from 'app/utilities/HttpClient';
import { uuidv4 } from "app/utilities/Utilities";

const _urlEmbeddings:string = `${config.chatAIApiUrl}/embeddings`;
const _urlConversations:string = `${config.chatAIApiUrl}/conversations`;

/**
 * @param {Object} data
 * @property {number} data.caseId
 * @property {number[]} data.documentIds
 */
export const createEmbeddings = asyncThunkHandler(
  `${Reducers.AIChat}/Create embeddings`,
  async (data:any, { dispatch, fulfillWithValue }) => {
    await $post(_urlEmbeddings, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Embeddings
    }));
    return fulfillWithValue('Embedding created');
  }
);

/**
 * @param {Object} param
 * @property {number} param.caseId
 */
export const getConversations = asyncThunkHandler(
  `${Reducers.AIChat}/Get conversations`,
  async (params:any) => {
    const response:Response = await $get(_urlConversations, params);
    return response.json();
  }
);

/**
 * @param {string} conversationId
 */
export const getConversation = asyncThunkHandler(
  `${Reducers.AIChat}/Get conversation`,
  async (conversationId:string) => {
    const response:Response = await $get(`${_urlConversations}/${conversationId}`);
    return response.json();
  }
);

/**
 * @param {Object} data
 * @property {number} data.caseId
 * @property {string} data.name
 * @property {string} data.promptId
 * @property {string} data.question
 * @property {Object} data.settings
 * @property {number} data.settings.ragMaxPages
 */
export const createConversation = asyncThunkHandler(
  `${Reducers.AIChat}/Create conversation`,
  async (data:any, { dispatch }) => {
    const { promptQuestion, ...otherData } = data;
    const response:Response = await $post(_urlConversations, otherData);

    // data.annotations - is passed when the conversation is created from `SearchPagesPage`
    // data.promptId || data.question - is passed when the conversation is created from `InitialConversationScreen` prompt or message
    const showSnackbar = !data.annotations && !(data.promptId || data.question);

    if ( showSnackbar ){
      dispatch(AppUiNotificationsActions.addSnackbar({
        message: Messages.Create
      }));
    }

    return response.json();
  }
);

/**
 * @param {string} conversationId
 * @param {Object} data
 * @property {string} data.name
 * @property {Object} data.settings
 * @property {number} data.settings.ragMaxPages
 */
export const updateConversation = asyncThunkHandler(
  `${Reducers.AIChat}/Update conversation`,
  async ({ conversationId, data }:{ conversationId:string, data:any }, { dispatch }) => {
    const response:Response = await $put(`${_urlConversations}/${conversationId}`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));
    return response.json();
  }
);

/**
 * @param {string} conversationId
 */
export const deleteConversation = asyncThunkHandler(
  `${Reducers.AIChat}/Delete conversation`,
  async (conversationId:string, { dispatch, fulfillWithValue }) => {
    await $delete(`${_urlConversations}/${conversationId}`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Delete
    }));
    return fulfillWithValue(conversationId)
  }
);

/**
 * @param {Object} questionData
 * @property {string} questionData.conversationId
 * @property {Object} questionData.data
 * @property {string} questionData.data.question
 */
export const createQuestion = asyncThunkHandler(
  `${Reducers.AIChat}/Create question`,
  async ({ conversationId, data }:{ conversationId:string, data:any }, { rejectWithValue, dispatch, fulfillWithValue }) => {
    const response:Response = await $post(`${_urlConversations}/${conversationId}`, data);
    if ( !response.ok ) {
      if ( response.status === 400 ){
        const error = await response.json();
        dispatch(AIChatActions.addErrorMessageFromAI({
          id: uuidv4(),
          createdOn: new Date().toString(),
          text: error.message || 'Your question is a bit broad. Can you provide more details or clarify so I can assist you better? Thanks!',
          type: 'ai',
          error: true
        }));
        // Throw an error to evoke reject action without calling `rejectWithoutValue`
        // `rejectWihtoutValue` needed to throw an text error in snackbar
        throw Error(`Getting question error with status ${response.status}`);
      }
      return rejectWithValue((await response.json()) as any);
    }
    const idempotencyKey = response.headers.get('Idempotency-Key');
    const message = await response.json();
    return fulfillWithValue({...message, idempotencyKey});
  }
);

/**
 * @param {string} conversationId
 */
export const refineAnswer = asyncThunkHandler(
  `${Reducers.AIChat}/Refine answer`,
  // `messageId` used in AIChat.middleware `refineAnswer.pending` action
  // `parentId` used in AIChat.slice `refineAnswer.pending` action
  async ({ messageId, parentId, conversationId }:{ messageId:string; parentId:string; conversationId:string }, { fulfillWithValue }) => {
    const response:Response = await $post(`${_urlConversations}/${conversationId}/actions/refine`);
    const idempotencyKey = response.headers.get('Idempotency-Key');
    const message = await response.json();
    return fulfillWithValue({...message, idempotencyKey});
  }
);
