import { FC } from 'react';
// Models
import { IPrompt } from 'app/store/AIChatPrompts/AIChatPrompts.models';
// Mui
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
// Components
import Labels from 'app/components/Labels';
// Utilities
import { truncateContent } from 'app/utilities/Utilities';

type Props = {
  aiPrompt: IPrompt;
  onClick?: () => void;
  width?: number;
  height?: number;
  maxChars?: number;
  flexWrap?: string;
}

const AIPromptItem:FC<Props> = ({
  // Props
  aiPrompt, onClick, width = 'auto', height = 'auto', maxChars = 100, flexWrap = 'wrap'
}) => {

  const enableCard = typeof onClick !== 'undefined';

  return (
    <Card sx={{
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 0,
      bgcolor: enableCard ? 'white' : 'rgba(0,0,0,0.025)',
      width,
      height
    }}>
      <CardActionArea sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
      }} onClick={onClick} disabled={!enableCard}>
        <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          <Typography variant="subtitle1">{truncateContent(aiPrompt.title, maxChars)}</Typography>
          <Typography sx={{
            flexGrow: 1,
            whiteSpace: 'pre-line',
            mb: 2
          }} color="GrayText" variant="body2">{truncateContent(aiPrompt.content, maxChars)}</Typography>
          {aiPrompt.labels ? (
            <Labels
              id={`prompt item ${aiPrompt.id} labels`}
              sx={{
                display: 'flex',
                flexWrap,
                gap: 2,
                overflow: 'hidden'
              }}
              labels={aiPrompt.labels}
            />
          ) : null}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default AIPromptItem;
